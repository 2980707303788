import { Link } from "react-router-dom"

import robotHand from "../resources/bg.png"
import Layout from "../components/Layout"
import { CONTACTS_PAGE_ROUTE, SUPPORTS_PAGE_ROUTE } from "../consts/routes"

const MainPage = () => {
  return (
    <Layout title="Центр трансфера технологий">
      <div className="mt-4 mb-5">
        <div className="row">
          <div className="col-xs-12 col-sm-8 col-lg-9 col-xl-8 home">
            <div className="home-content">
              <h1 className="mb-5">
                Центр
                <br />
                трансфера
                <br />
                технологий
                <br />
                Пермского
                <br />
                Политеха
              </h1>
              <h4 className="mb-5">
                Технологические&nbsp;решения&nbsp;
                <wbr />
                для&nbsp;бизнеса
              </h4>
              <div className="kontakty">
                <Link
                  to={CONTACTS_PAGE_ROUTE}
                  className="btn btn-primary napisat"
                >
                  Написать в ЦТТПП
                </Link>
                <Link
                  to={SUPPORTS_PAGE_ROUTE}
                  className="btn btn-primary prezentaciya"
                >
                  Презентация ЦТТПП
                </Link>
              </div>
            </div>
          </div>
          <div className="col-4 col-xl-4 col-lg-3  home-img d-none d-sm-block">
            <img
              src={robotHand}
              alt="Robot Hand"
              className="float-end"
              width="200%"
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default MainPage
