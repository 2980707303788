import { ChangeEvent, memo, useCallback, useMemo, useState } from "react"
import Slider from "rc-slider"
import debounce from "lodash.debounce"

import { useActionCreators, useTypedSelector } from "../../hooks/redux"

const SumRange = () => {
  const { setSupportFilterRange } = useActionCreators()

  const totalMinValue = useTypedSelector(
    (state) => state.filterReducer.supports.totalMinAmount,
  )
  const totalMaxValue = useTypedSelector(
    (state) => state.filterReducer.supports.totalMaxAmount,
  )
  const minValue = useTypedSelector(
    (state) => state.filterReducer.supports.totalMinAmount,
  )
  const maxValue = useTypedSelector(
    (state) => state.filterReducer.supports.totalMaxAmount,
  )

  const [range, setRange] = useState([minValue, maxValue])

  const changeRange = useCallback(
    (range: number[]) => {
      Array.isArray(range) && setSupportFilterRange(range)
    },
    [setSupportFilterRange],
  )

  const debouncedCb = useMemo(() => debounce(changeRange, 500), [changeRange])

  const handleRangeChange = (range: number | number[]) => {
    if (Array.isArray(range)) {
      setRange(range)

      debouncedCb(range)
    }
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    const updatedValue = parseInt(value)

    if (isNaN(updatedValue)) {
      return
    }

    const newValues = [...range]
    newValues[name === "min" ? 0 : 1] = updatedValue
    setRange(newValues)

    debouncedCb(newValues)
  }

  return (
    <>
      <input
        type="number"
        className="filtr-ot form-control"
        name="min"
        value={range[0]}
        onChange={handleInputChange}
      />{" "}
      -{" "}
      <input
        type="number"
        className="filtr-do form-control"
        name="max"
        value={range[1]}
        maxLength={7}
        onChange={handleInputChange}
      />
      <Slider
        range
        className="form-range mt-4"
        min={totalMinValue}
        max={totalMaxValue}
        value={range}
        onChange={handleRangeChange}
      />
    </>
  )
}

export default memo(SumRange)
