import { useMemo } from "react"
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux"
import { bindActionCreators } from "@reduxjs/toolkit"

import { AppDispatch, RootState } from "../store/store"
import { filterSlice } from "../store/reducers/filterSlice"
import { newsSlice } from "../store/reducers/newsSlice"
import { modalSlice } from "../store/reducers/modalSlice"
import { userSlice } from "../store/reducers/userSlice"

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector

export const useActionCreators = () => {
  const dispatch = useAppDispatch()

  const actions = useMemo(
    () => ({
      ...filterSlice.actions,
      ...newsSlice.actions,
      ...modalSlice.actions,
      ...userSlice.actions,
    }),
    [],
  )

  return useMemo(
    () => bindActionCreators(actions, dispatch),
    [actions, dispatch],
  )
}
