export function isLink(value: string) {
  const regex = /^https?:\/\/[\w-]+(\.[\w-]+)*\.[a-zA-Z]{2,}(\/\S*)?$/

  return regex.test(value)
}

export function isYoutubeLink(value: string) {
  if (!isLink(value)) return

  const youtubeUrlPattern =
    /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+$/

  // Test the pattern against the passed URL
  return youtubeUrlPattern.test(value)
}
