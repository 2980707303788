import { FC } from "react"

interface InputRowProps {
  label: string
  name?: string
  type: "checkbox" | "radio"
  id: string
  dataId: string
}

const InputRow: FC<InputRowProps> = ({ name, label, type, id, dataId }) => {
  return (
    <div className="input-row my-2">
      <input type={type} name={name} id={id} data-id={dataId} />
      <label htmlFor={id} className="m-0">
        {label}
      </label>
    </div>
  )
}

export default InputRow
