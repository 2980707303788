import { memo, useRef } from "react"

import { instituteApi } from "../../services/instituteService"
import { supportApi } from "../../services/supportService"
import { useTypedSelector } from "../../hooks/redux"
import FilterGroup from "../SidebarFilter/FilterGroup"

const InstituteFilter = () => {
  const { data: instituteForms } = instituteApi.useGetInstituteFormsQuery()
  const { data: supportForms } = supportApi.useGetSupportFormsQuery()
  const { data: supportMembers } = supportApi.useGetSupportMembersQuery()

  const filter = useTypedSelector((state) => state.filterReducer.institution)

  const formsLengthRef = useRef(filter.support_forms.length)
  const membersLengthRef = useRef(filter.members.length)

  return (
    <div className="col-xs-12 col-xxl-3 col-sm-12 col-md-3">
      <aside>
        <div className="filtr">
          <form className="mb-5">
            {instituteForms && (
              <FilterGroup
                id="collapse1"
                initIsOpen
                title="Форма института поддержки"
                items={instituteForms}
                name="forms"
                reducerName="institution"
              />
            )}
            {supportForms && (
              <FilterGroup
                id="collapse2"
                initIsOpen={formsLengthRef.current > 0}
                title="Форма поддержки"
                items={supportForms}
                name="support_forms"
                reducerName="institution"
              />
            )}
            {supportMembers && (
              <FilterGroup
                id="collapse3"
                initIsOpen={membersLengthRef.current > 0}
                title="Участник"
                items={supportMembers}
                name="members"
                reducerName="institution"
              />
            )}
          </form>
        </div>
      </aside>
    </div>
  )
}

export default memo(InstituteFilter)
