import { createApi } from "@reduxjs/toolkit/query/react"

import { IDefaultQuery, IDefaultResponse } from "../types/api"
import {
  Institute,
  InstituteForm,
  Institutes,
  InstituteSearch,
} from "../types/institutes"
import { Support, SupportForm } from "../types/supports"
import { UniversalFilterType } from "../types/common"

import { baseQuery } from "./baseQuery"

export const instituteApi = createApi({
  reducerPath: "institute",
  baseQuery,
  endpoints: (build) => ({
    getInstitutes: build.query<Institutes, IDefaultQuery<InstituteSearch>>({
      query: ({ page, search }) => ({
        url: `/getinstitutes/${page}`,
        body: search ? { search } : {},
        method: "POST",
      }),
      transformResponse(response: IDefaultResponse<Institute[]>) {
        return {
          list: response.data ?? [],
          count: response.count ?? 0,
        }
      },
    }),
    getInstitute: build.query<IDefaultResponse<Institute>, number>({
      query: (id) => ({
        url: `/getinstitute/${id}`,
      }),
    }),
    getInstituteForms: build.query<UniversalFilterType[], void>({
      query: () => ({
        url: "/getinstitutionforms",
      }),
      transformResponse(response: IDefaultResponse<InstituteForm[]>) {
        return (
          response.data?.map((form) => ({
            id: form.id_institution_forms,
            title: form.institution_form_name,
          })) ?? []
        )
      },
    }),
    getSupportsForInstitute: build.query<IDefaultResponse<Support[]>, number>({
      query: (id) => ({
        url: `/getactualsupports/${id}`,
      }),
    }),
    getSupportsFormsForInstitute: build.query<
      IDefaultResponse<SupportForm[]>,
      number
    >({
      query: (id) => ({
        url: `/getinstitutionsupportforms/${id}`,
      }),
    }),
  }),
})
