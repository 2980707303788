import { innovationApi } from "../../services/innovationService"

import GroupInput from "./Inputs/GroupInput"
import TextAreaInput from "./Inputs/TextAreaInput"
import GroupSelect from "./Inputs/GroupSelect"

const ShortInfo = () => {
  const { data: defaultFields } = innovationApi.useGetDefaultFieldsQuery()
  const { data: fetchedStages } = innovationApi.useGetStagesQuery()

  return (
    <>
      <h3 className="fw-bold">Краткая информация о проекте</h3>

      <GroupInput
        id="innovation_name"
        name="innovation_name"
        labelText="Название проекта"
        inputOptions={{
          required: true,
          className: "w-100",
        }}
      />

      <GroupInput
        id="innovation_wallpaper"
        name="wallpaper"
        labelText="Заставка проекта"
        inputOptions={{
          required: true,
          className: "w-100 form-control",
          type: "file",
          accept: "image/png, image/jpeg",
        }}
      />

      <GroupInput
        id="innovation_logo"
        name="logo"
        labelText="Логотип проекта или компании"
        inputOptions={{
          className: "w-100 form-control",
          type: "file",
          accept: "image/png, image/jpeg",
        }}
      />

      {defaultFields?.description?.active && (
        <TextAreaInput name="description" field={defaultFields.description} />
      )}

      {defaultFields?.keywords?.active && (
        <TextAreaInput field={defaultFields.keywords} name="keywords" />
      )}

      {fetchedStages?.data && (
        <GroupSelect
          id="innovation_stage"
          label="Стадия проекта"
          required
          options={fetchedStages.data}
          optionIdProp="id_stages"
          optionValueProp="stage_name"
          name="id_stages"
        />
      )}

      {defaultFields?.site?.active && (
        <GroupInput
          id="innovation_site"
          name="site"
          labelText={defaultFields.site.label}
          tooltip={defaultFields.site.tooltip}
          inputOptions={{
            required: defaultFields.site.required,
            type: "url",
          }}
        />
      )}
    </>
  )
}

export default ShortInfo
