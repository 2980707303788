import {
  CONFIRM_EMAIL_PAGE_ROUTE,
  CONTACTS_PAGE_ROUTE,
  INNOVATION_CREATE_ROUTE,
  INSTITUTE_PAGE_ROUTE,
  INSTITUTES_PAGE_ROUTE,
  MAIN_PAGE_ROUTE,
  NEWS_DETAILED_PAGE_ROUTE,
  NEWS_PAGE_ROUTE,
  NEWS_PAGE_TAG_ROUTE,
  NOT_FOUND_PAGE_ROUTE,
  PERSONAL_ACCOUNT_ROUTE,
  PROJECT_PAGE_ROUTE,
  PROJECTS_PAGE_ROUTE,
  RULES_PAGE_ROUTE,
  STATIC_PAGE_ROUTE,
  SUPPORT_PAGE_ROUTE,
  SUPPORTS_PAGE_ROUTE,
} from "../consts/routes"
import MainPage from "../pages/MainPage"
import SupportsPage from "../pages/SupportsPage"
import NotFoundPage from "../pages/NotFoundPage"
import SupportPage from "../pages/SupportPage"
import InstitutesPage from "../pages/InstitutesPage"
import InstitutePage from "../pages/InstitutePage"
import RulesPage from "../pages/RulesPage"
import ContactsPage from "../pages/ContactsPage"
import NewsPage from "../pages/NewsPage"
import NewsDetailedPage from "../pages/NewsDetailedPage"
import StaticPage from "../pages/StaticPage"
import InnovationsPage from "../pages/InnovationsPage"
import InnovationPage from "../pages/InnovationPage"
import PersonalPage from "../pages/PersonalPage"
import InnovationEditorPage from "../pages/InnovationEditorPage"
import ConfirmEmailPage from "../pages/ConfirmEmailPage"

export const routes = [
  {
    path: MAIN_PAGE_ROUTE,
    Component: MainPage,
  },
  {
    path: PROJECTS_PAGE_ROUTE,
    Component: InnovationsPage,
  },
  {
    path: PROJECT_PAGE_ROUTE,
    Component: InnovationPage,
  },
  {
    path: SUPPORTS_PAGE_ROUTE,
    Component: SupportsPage,
  },
  {
    path: SUPPORT_PAGE_ROUTE,
    Component: SupportPage,
  },
  {
    path: INSTITUTES_PAGE_ROUTE,
    Component: InstitutesPage,
  },
  {
    path: INSTITUTE_PAGE_ROUTE,
    Component: InstitutePage,
  },
  {
    path: RULES_PAGE_ROUTE,
    Component: RulesPage,
  },
  {
    path: CONTACTS_PAGE_ROUTE,
    Component: ContactsPage,
  },
  {
    path: NEWS_PAGE_ROUTE,
    Component: NewsPage,
  },
  {
    path: NEWS_PAGE_TAG_ROUTE,
    Component: NewsPage,
  },
  {
    path: NEWS_DETAILED_PAGE_ROUTE,
    Component: NewsDetailedPage,
  },
  {
    path: STATIC_PAGE_ROUTE,
    Component: StaticPage,
  },
  {
    path: CONFIRM_EMAIL_PAGE_ROUTE,
    Component: ConfirmEmailPage,
  },
  {
    path: NOT_FOUND_PAGE_ROUTE,
    Component: NotFoundPage,
  },
]

export const authRoutes = [
  {
    path: PERSONAL_ACCOUNT_ROUTE,
    Component: PersonalPage,
  },
  {
    path: INNOVATION_CREATE_ROUTE,
    Component: InnovationEditorPage,
  },
]
