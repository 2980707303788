import { createApi } from "@reduxjs/toolkit/query/react"

import { IDefaultQuery, IDefaultResponse } from "../types/api"
import { Static, Statics, StaticSearch, StaticStatus } from "../types/statics"

import { baseQuery } from "./baseQuery"

export const staticApi = createApi({
  reducerPath: "static",
  baseQuery,
  endpoints: (build) => ({
    getList: build.query<Statics, IDefaultQuery<StaticSearch>>({
      query: ({ page, search }) => ({
        url: `/getstaticpages/${page}`,
        body: search ? { search } : {},
        method: "POST",
      }),
      transformResponse(response: IDefaultResponse<Static[]>) {
        return {
          list: response.data ?? [],
          count: response.count || 0,
        }
      },
    }),
    getOne: build.query<IDefaultResponse<Static>, string>({
      query: (id) => ({
        url: `/getstaticpage/${id}`,
      }),
    }),
    getStatuses: build.query<IDefaultResponse<StaticStatus[]>, void>({
      query: () => ({
        url: "/getstaticpagestatuses",
      }),
    }),
  }),
})
