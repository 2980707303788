import { memo } from "react"

import logo_ctt from "../resources/logo-ctt.svg"
import logo_pol from "../resources/logo-pol.svg"
import {
  ADDRESS,
  EMAIL,
  EMAIL_LINK,
  NETWORKS_FIRST_MARGIN,
} from "../consts/contacts"

import Feedback from "./Feedback"

const Footer = () => {
  return (
    <footer>
      {/*<div className="container logo">*/}
      {/*    <div className="row">*/}
      {/*        <h2 className="h1-st">Наши партнеры</h2>*/}
      {/*        <hr/>*/}
      {/*            <div className="col-6 col-xs-6 col-md-4 col-lg-3 col-xl-2 col-sm-6"><a href="" className="footer-h"><img src="https://www.anypics.ru/mini/201211/48868.jpg"/></a></div>*/}
      {/*            <div className="col-6 col-xs-6 col-md-4 col-lg-3 col-xl-2 col-sm-6"><a href="" className="footer-h"><img src="https://pbs.twimg.com/media/CJ1V7QKUAAAD2p3.png"/></a></div>*/}
      {/*            <div className="col-6 col-xs-6 col-md-4 col-lg-3 col-xl-2 col-sm-6"><a href="" className="footer-h"><img src="https://fasie.ru/local/templates/.default/markup/img/head_logo_fasie.png"/></a></div>*/}
      {/*            <div className="col-6 col-xs-6 col-md-4 col-lg-3 col-xl-2 col-sm-6"><a href="" className="footer-h"><img src="https://w7.pngwing.com/pngs/106/702/png-transparent-fizzy-drinks-coca-cola-pepsi-diner-pepsi-kitchen-text-trademark-thumbnail.png"/></a></div>*/}
      {/*            <div className="col-6 col-xs-6 col-md-4 col-lg-3 col-xl-2 col-sm-6"><a href="" className="footer-h"><img src="https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/i/65c02c84-436e-4364-b648-2c6898641a09/d1ph4ig-2a9897c2-a6f9-4ca1-ae1c-288a99c894b7.jpg/v1/fill/w_164,h_164,q_75,strp/lsdapple_by_strangejames_d1ph4ig-fullview.jpg"/></a></div>*/}
      {/*            <div className="col-6 col-xs-6 col-md-4 col-lg-3 col-xl-2 col-sm-6"><a href="" className="footer-h"><img src="https://www.anypics.ru/mini/201211/48868.jpg"/></a></div>*/}
      {/*            <div className="col-6 col-xs-6 col-md-4 col-lg-3 col-xl-2 col-sm-6"><a href="" className="footer-h"><img src="https://pbs.twimg.com/media/CJ1V7QKUAAAD2p3.png"/></a></div>*/}
      {/*            <div className="col-6 col-xs-6 col-md-4 col-lg-3 col-xl-2 col-sm-6"><a href="" className="footer-h"><img src="https://fasie.ru/local/templates/.default/markup/img/head_logo_fasie.png"/></a></div>*/}
      {/*            <div className="col-6 col-xs-6 col-md-4 col-lg-3 col-xl-2 col-sm-6"><a href="" className="footer-h"><img src="https://w7.pngwing.com/pngs/106/702/png-transparent-fizzy-drinks-coca-cola-pepsi-diner-pepsi-kitchen-text-trademark-thumbnail.png"/></a></div>*/}
      {/*            <div className="col-6 col-xs-6 col-md-4 col-lg-3 col-xl-2 col-sm-6"><a href="" className="footer-h"><img src="https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/i/65c02c84-436e-4364-b648-2c6898641a09/d1ph4ig-2a9897c2-a6f9-4ca1-ae1c-288a99c894b7.jpg/v1/fill/w_164,h_164,q_75,strp/lsdapple_by_strangejames_d1ph4ig-fullview.jpg"/></a></div>*/}
      {/*            <div className="col-6 col-xs-6 col-md-4 col-lg-3 col-xl-2 col-sm-6"><a href="" className="footer-h"><img src="https://www.anypics.ru/mini/201211/48868.jpg"/></a></div>*/}
      {/*            <div className="col-6 col-xs-6 col-md-4 col-lg-3 col-xl-2 col-sm-6"><a href="" className="footer-h"><img src="https://pbs.twimg.com/media/CJ1V7QKUAAAD2p3.png"/></a></div>*/}
      {/*            <div className="col-6 col-xs-6 col-md-4 col-lg-3 col-xl-2 col-sm-6"><a href="" className="footer-h"><img src="https://fasie.ru/local/templates/.default/markup/img/head_logo_fasie.png"/></a></div>*/}
      {/*            <div className="col-6 col-xs-6 col-md-4 col-lg-3 col-xl-2 col-sm-6"><a href="" className="footer-h"><img src="https://w7.pngwing.com/pngs/106/702/png-transparent-fizzy-drinks-coca-cola-pepsi-diner-pepsi-kitchen-text-trademark-thumbnail.png"/></a></div>*/}
      {/*            <div className="col-6 col-xs-6 col-md-4 col-lg-3 col-xl-2 col-sm-6"><a href="" className="footer-h"><img src="https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/i/65c02c84-436e-4364-b648-2c6898641a09/d1ph4ig-2a9897c2-a6f9-4ca1-ae1c-288a99c894b7.jpg/v1/fill/w_164,h_164,q_75,strp/lsdapple_by_strangejames_d1ph4ig-fullview.jpg"/></a></div>*/}
      {/*    </div>*/}
      {/*</div>*/}

      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-5 mt-2">
              <div className="logo">
                <img
                  src={logo_ctt}
                  alt="ЦТТ"
                  className="mt-0 pt-2 mb-4 d-inline-block"
                />
                <img
                  src={logo_pol}
                  alt="Пермский политех"
                  className="mt-0 mb-4"
                />
              </div>
              <h4>
                Центр трансфера технологий
                <br />
                Пермского Политеха
              </h4>
              <p className="footadres">{ADDRESS}</p>
              {/*<p className="foottel">*/}
              {/*  <b>Телефон:</b> <a href={PHONE_LINK}>{PHONE}</a>*/}
              {/*</p>*/}
              <p className="footmail">
                <b>E-mail:</b> <a href={EMAIL_LINK}>{EMAIL}</a>
              </p>
              <div className="soc">{NETWORKS_FIRST_MARGIN}</div>
            </div>
            <div className="col-12 col-md-6 col-lg-5 offset-lg-2 mt-2">
              <Feedback isFooter />
            </div>
          </div>
          <div className="row hr-foot">
            <p>© 2021 ЦТТПП</p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default memo(Footer)
