import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface InitialState {
  currentTag: null | string
}

const initialState: InitialState = {
  currentTag: null,
}

export const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {
    switchTag(state, action: PayloadAction<string | null>) {
      state.currentTag = action.payload
    },
  },
})

export const newsReducer = newsSlice.reducer
