import vk from "../resources/vk.svg"
import vkWhite from "../resources/vk-white.svg"

export const TELEGRAM = "https://t.me/permctt"
export const VK = "https://vk.com/innovation_pstu"
export const ADDRESS =
  "614990, г. Пермь, Комсомольский проспект, 29, ауд. 418 (корпус А)"
// export const PHONE = "+7 (342) 2-198-029"
// export const PHONE_LINK = "tel:+73422198029"
export const EMAIL = "info@permctt.ru"
export const EMAIL_LINK = `maito:${EMAIL}`
export const HELP_EMAIL = "help@permctt.ru"
export const SITE = "permctt.ru"

export const NETWORKS = [
  <a
    href={VK}
    key="1"
    target="_blank"
    rel="noopener noreferrer"
    className="vk-logo-home"
  >
    <img src={vk} alt="" />
  </a>,
  <a key="2" href={TELEGRAM} target="_blank" rel="noopener noreferrer">
    <i className="fa fa-telegram" aria-hidden="true" />
  </a>,
]

export const NETWORKS_FIRST_MARGIN = [
  <a
    className="vk-logo-footer"
    key="1"
    href={VK}
    target="_blank"
    rel="noopener noreferrer"
  >
    <img src={vkWhite} alt="" />
  </a>,
  <a key="2" href={TELEGRAM} target="_blank" rel="noopener noreferrer">
    <i className="fa fa-telegram" aria-hidden="true" />
  </a>,
]

export const ADDRESS_WITH_UNIVERSITY_NAME = (
  <p className="footadres">
    {ADDRESS}
    <br />
    Пермский национальный исследовательский
    <br />
    политехнический университет
  </p>
)
