import { FC, memo } from "react"
import ReactPaginate from "react-paginate"

interface PaginateProps {
  initPage: number
  pageCount: number
  onPageChange: (e: { selected: number }) => void
}

const Paginate: FC<PaginateProps> = ({ initPage, pageCount, onPageChange }) => {
  return (
    <ReactPaginate
      breakLabel="..."
      nextLabel={<i className="fa fa-angle-right" aria-hidden="true" />}
      onPageChange={onPageChange}
      pageRangeDisplayed={5}
      pageCount={pageCount || 1}
      initialPage={initPage}
      previousLabel={<i className="fa fa-angle-left" aria-hidden="true" />}
      containerClassName="pagination"
      pageClassName="page-item"
      previousClassName="page-item"
      nextClassName="page-item"
      breakClassName="page-item"
      pageLinkClassName="page-link"
      previousLinkClassName="page-link"
      nextLinkClassName="page-link"
      breakLinkClassName="page-link"
      activeLinkClassName="page-link-active"
    />
  )
}

export default memo(Paginate)
