import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { User } from "../../types/user"

const initialState: { user: User | null } = {
  user: null,
}

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<User | null>) {
      state.user = action.payload
    },
  },
})

export const userReducer = userSlice.reducer
