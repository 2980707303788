import { useEffect } from "react"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"

import { HOST, LOCALHOST } from "../consts/hosts"
import defaultImage from "../resources/default_image.png"
import Layout from "../components/Layout"
import { newsApi } from "../services/newsService"
import { NEWS_PAGE_ROUTE, NOT_FOUND_PAGE_ROUTE } from "../consts/routes"
import NewsCard from "../components/Cards/NewsCard"
import { useActionCreators, useTypedSelector } from "../hooks/redux"

const NewsDetailedPage = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const location = useLocation()

  const [fetchNews, { data: news }] = newsApi.useLazyGetNewsDetailedQuery()

  const { switchTag } = useActionCreators()

  const user = useTypedSelector((state) => state.userReducer.user)

  const handleTagLinkClick = (tag: string | null) => {
    switchTag(tag)
  }

  useEffect(() => {
    if (id === undefined) {
      navigate(NOT_FOUND_PAGE_ROUTE)
    }

    if (id) {
      fetchNews(id).then(({ data }) => {
        if (data?.data === null) {
          navigate(NOT_FOUND_PAGE_ROUTE)
        }
      })
    }
  }, [fetchNews, id, navigate])

  return (
    <Layout
      title={news?.data?.title_meta ?? "Новости - Центр трансфера технологий"}
      description={news?.data?.description_meta}
      keywords={news?.data?.keywords_meta}
    >
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-xxl-12">
          <section className="mt-4 kartochka knews">
            <article className="mb-4">
              <div className="article">
                {news?.data && user && [1, 5].includes(user.groups_id_groups) && (
                  <a
                    className="btn btn-primary d-inline-block mb-4 col-8 col-sm-6 col-md-4 col-xxl-3"
                    href={`https://admin.permctt.ru/news/${news.data.id_news}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Редактировать
                  </a>
                )}

                <div className="kn">
                  {news?.data?.news_categories.map((category) => (
                    <div
                      className="a-firma float-end mb-2"
                      key={category.id_news_categories}
                    >
                      <Link
                        to={NEWS_PAGE_ROUTE + `/tag/${category.alt_name}`}
                        onClick={() => handleTagLinkClick(category.alt_name)}
                      >
                        {category.title}
                      </Link>
                    </div>
                  ))}

                  <div className="clr clr-kn-h1" />
                  <h1 className="mt-1 mb-5">{news?.data?.title}</h1>

                  <div className="clr" />
                  <div className="news-img mb-5">
                    <img
                      src={
                        news?.data?.logo
                          ? `${HOST}/${news.data.logo}`
                          : defaultImage
                      }
                      alt={news?.data?.title}
                    />
                  </div>

                  <div
                    dangerouslySetInnerHTML={{
                      __html: news?.data?.full_story || "",
                    }}
                  />

                  <div className="clr" />
                  <div className="a-date k-dp mb-4 mt-4 float-start">
                    <i className="fa fa-clock-o" aria-hidden="true" />{" "}
                    {news?.data?.date_creation_dd}
                  </div>

                  <div className="kn-soc k-dp mb-4 mt-4 float-end">
                    <b>Поделиться новостью:</b>
                    <a
                      href={`https://vk.com/share.php?url=${LOCALHOST}${location.pathname}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa fa-vk" aria-hidden="true" />
                    </a>
                    <a
                      href={`https://connect.ok.ru/offer?url=${LOCALHOST}${location.pathname}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa fa-odnoklassniki" aria-hidden="true" />
                    </a>
                  </div>
                  <div className="clr" />
                </div>
                <h5 className="related mt-4 mb-4">Похожие новости</h5>

                <div className="row news inst">
                  {news?.data?.related?.map((related) => (
                    <NewsCard
                      key={related.id_news}
                      news={related}
                      handleCategoryClick={() => {}}
                    />
                  ))}
                </div>
              </div>
            </article>
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default NewsDetailedPage
