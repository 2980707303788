import { useEffect, useState } from "react"
import { Link } from "react-router-dom"

import { useActionCreators, useTypedSelector } from "../hooks/redux"
import { userApi } from "../services/userService"

import UnsubscribeModal from "./Modals/UnsubscribeModal"
import ChangeSubscriptionTitleModal from "./Modals/ChangeSubscriptionTitleModal"

const SubscriptionTable = () => {
  const actions = useActionCreators()

  const [fetchSubscriptions, { data: subscriptions }] =
    userApi.useLazyGetSubscriptionsQuery()

  const userId = useTypedSelector((state) => state.userReducer.user?.id)

  const [subToEdit, setSubToEdit] = useState<{
    id: number
    title: string
  } | null>(null)
  const [subIdToDelete, setSubIdToDelete] = useState<number>(0)

  const onUnsubscribe = (id: number) => {
    setSubIdToDelete(id)
    actions.setUnsubscribeModalVisible(true)
  }

  const onEdit = (id: number, title: string) => {
    setSubToEdit({ id, title })
    actions.setChangeSubscriptionTitleModalVisible(true)
  }

  useEffect(() => {
    if (userId === undefined) return

    fetchSubscriptions(userId)
  }, [fetchSubscriptions, userId])

  if (!subscriptions?.data?.length) {
    return <p>Нет подписок</p>
  }

  return (
    <>
      <table className="mb-4">
        <tbody>
          <tr className="title-lk">
            <td>Название</td>
            <td>URL</td>
            <td></td>
          </tr>
          {subscriptions.data.map((sub) => (
            <tr key={sub.id_subscriptions}>
              <td>
                <button
                  type="button"
                  className="btn btn-link text-dark p-0 text-decoration-none text-start subscription__title-btn"
                  onClick={() =>
                    onEdit(sub.id_subscriptions, sub.subscription_name)
                  }
                >
                  {sub.subscription_name}
                </button>
              </td>
              <td>
                <Link to={sub.url} target="_blank" title={sub.url}>
                  {window.location.origin + sub.url}
                </Link>
              </td>
              <td>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => onUnsubscribe(sub.id_subscriptions)}
                >
                  Отписаться
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <UnsubscribeModal subId={subIdToDelete} />
      {subToEdit && (
        <ChangeSubscriptionTitleModal
          subscriptionId={subToEdit.id}
          initialTitle={subToEdit.title}
        />
      )}
    </>
  )
}

export default SubscriptionTable
