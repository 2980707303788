import { FC, PropsWithChildren } from "react"

const InfoField: FC<PropsWithChildren<{ title: string }>> = ({
  title,
  children,
}) => {
  return (
    <>
      <h2 className="mt-5 mb-2">{title}</h2>
      {children}
    </>
  )
}

export default InfoField
