import { useCallback, useEffect, useMemo } from "react"
import { Link, useLocation, useSearchParams } from "react-router-dom"

import Layout from "../components/Layout"
import Paginate from "../components/Paginate"
import InnovationCard from "../components/Cards/InnovationCard"
import { innovationApi } from "../services/innovationService"
import { useActionCreators, useTypedSelector } from "../hooks/redux"
import { useDebounce } from "../hooks/useDebounce"
import InnovationFilter from "../components/ItemsFilter/InnovationFilter"
import { getObjectFromQuery } from "../utils/getObjectFromQuery"
import SubscribeModal from "../components/Modals/SubscribeModal"
import AuthModal from "../components/Modals/AuthModal"

const InnovationsPage = () => {
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const actions = useActionCreators()

  const [getInnovations, { data: innovations }] =
    innovationApi.useLazyGetInnovationsQuery()

  const filter = useTypedSelector((state) => state.filterReducer.innovations)
  const user = useTypedSelector((state) => state.userReducer.user)
  const debouncedFilter = useDebounce(filter, 1000)

  const initialPage = useMemo(() => {
    const page = searchParams.get("page")

    if (page === null) return 1

    return +page ?? 1
  }, [searchParams])

  const pageCount = useMemo(
    () =>
      innovations?.count ? Math.ceil(innovations.count / filter.page_size) : 0,
    [filter.page_size, innovations],
  )

  const onPageChange = useCallback(
    (e: { selected: number }) =>
      actions.setInnovationFilter({ id: "page", value: e.selected + 1 }),
    [actions],
  )

  useEffect(() => {
    setSearchParams({
      industries: filter.industries.join(","),
      innovation_markets: filter.innovation_markets.join(","),
      legal_entity:
        filter.legal_entity === null
          ? ""
          : filter.legal_entity
          ? "true"
          : "false",
      fundraising:
        filter.fundraising === null
          ? ""
          : filter.fundraising
          ? "true"
          : "false",
      innovation_name: filter.innovation_name,
      trl: filter.trl.join(","),
      page: `${filter.page}`,
      page_size: `${filter.page_size}`,
    })
  }, [filter, location.search, searchParams, setSearchParams])

  useEffect(() => {
    getInnovations({
      page: debouncedFilter.page,
      search: {
        maxelem: debouncedFilter.page_size,
        innovation_name: debouncedFilter.innovation_name,
        trl: [...debouncedFilter.trl].sort((prev, cur) => prev - cur).join(","),
        legal_entity:
          debouncedFilter.legal_entity === null
            ? undefined
            : debouncedFilter.legal_entity,
        fundraising:
          debouncedFilter.fundraising === null
            ? undefined
            : debouncedFilter.fundraising,
        industries: debouncedFilter.industries.join(","),
        innovation_markets: debouncedFilter.innovation_markets.join(","),
      },
    })
  }, [debouncedFilter, getInnovations])

  useEffect(() => {
    actions.setInnovationFilterFromUrl(
      getObjectFromQuery(
        location.search,
        ["industries", "innovation_markets", "trl"],
        ["legal_entity", "fundraising"],
      ),
    )
  }, [actions, location.search])

  return (
    <Layout title="Инновационные разработки - Центр трансфера технологий">
      <div className="row vitrina">
        <div className="d-flex flex-column align-items-start align-items-md-center justify-content-md-between flex-md-row">
          <h1 className="h1-st mb-4 mt-4">Витрина проектов</h1>
          {user ? (
            <Link className="btn btn-primary" to="/innovations/add">
              Подать заявку
            </Link>
          ) : (
            <button
              className="btn btn-primary"
              style={{ height: "fit-content" }}
              onClick={() => actions.setAuthModalVisible(true)}
            >
              Подать заявку
            </button>
          )}
        </div>
        <InnovationFilter />

        <div className="col-xs-12 col-sm-12 col-md-9 col-xxl-9">
          <section className="mb-4 inst">
            <div className="sort mb-4">
              <form className="search">
                <input
                  type="text"
                  placeholder="Поиск проектов"
                  value={filter.innovation_name}
                  onChange={(e) =>
                    actions.setInnovationFilter({
                      id: "innovation_name",
                      value: e.target.value,
                    })
                  }
                />
                <button type="button">
                  <i className="fa fa-search" aria-hidden="true"></i>
                </button>
              </form>
            </div>

            <div className="row">
              {innovations?.data?.map((innovation) => (
                <InnovationCard
                  key={innovation.id_innovations}
                  innovation={innovation}
                />
              ))}

              <div className="clr" />

              <Paginate
                initPage={initialPage - 1}
                pageCount={pageCount || 1}
                onPageChange={onPageChange}
              />
            </div>
          </section>
        </div>
      </div>
      <SubscribeModal />
      <AuthModal />
    </Layout>
  )
}

export default InnovationsPage
