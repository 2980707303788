export function getQueryString<T extends object>(filter: T) {
  const queryArr: string[] = []

  for (const [key, value] of Object.entries(filter)) {
    if (
      value === null ||
      value === undefined ||
      value === "" ||
      (Array.isArray(value) && value.length === 0)
    )
      continue

    if (Array.isArray(value)) {
      queryArr.push(`${key}=${value.join(",")}`)
    } else {
      queryArr.push(`${key}=${value}`)
    }
  }

  return "?" + queryArr.join("&")
}
