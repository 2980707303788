import { FC, memo } from "react"

import Card from "../../Card"

import GroupInput from "./GroupInput"

interface TeamMemberInputsProps {
  idPrefix: string
  labelText: string
  name: string
  memberId?: number
  onRemove?: (id: number) => void
  isLeader?: boolean
}

const TeamMemberInputs: FC<TeamMemberInputsProps> = ({
  idPrefix,
  labelText,
  onRemove,
  memberId,
  name,
  isLeader,
}) => {
  return (
    <Card className="member">
      <div className="d-flex align-items-center justify-content-between mb-4">
        <h5 className="card-title fw-bold">{labelText}</h5>
        {!isLeader && (
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => onRemove && memberId && onRemove(memberId)}
          >
            Удалить
          </button>
        )}
      </div>
      <GroupInput
        id={idPrefix + "_fio"}
        name={`${name}_fio`}
        labelText="ФИО"
        inputOptions={{
          className: "w-100 form-control",
          required: true,
        }}
      />

      <GroupInput
        id={idPrefix + "_position"}
        name={`${name}_position`}
        labelText="Должность"
        inputOptions={{
          className: "w-100 form-control",
          required: true,
        }}
      />

      <GroupInput
        id={idPrefix + "_degree"}
        name={`${name}_degree`}
        labelText="Ученая степень"
        inputOptions={{
          className: "w-100 form-control",
          required: true,
        }}
      />

      <GroupInput
        id={idPrefix + "_photo"}
        name={`${name}_photo`}
        labelText="Фото"
        inputOptions={{
          className: "w-100 form-control",
          type: "file",
          accept: "image/png, image/jpeg",
        }}
      />
    </Card>
  )
}

export default memo(TeamMemberInputs)
