import { useState } from "react"

import Card from "../Card"
import { innovationApi } from "../../services/innovationService"

import CheckboxRadioInput from "./Inputs/CheckboxRadioInput"
import GroupInput from "./Inputs/GroupInput"
import GroupSelect from "./Inputs/GroupSelect"
import TextAreaInput from "./Inputs/TextAreaInput"

const DetailedInfo = () => {
  const { data: defaultFields } = innovationApi.useGetDefaultFieldsQuery()
  const { data: fetchedIndustries } = innovationApi.useGetIndustriesQuery()
  const { data: fetchedMarkets } = innovationApi.useGetMarketsQuery()
  const { data: fetchedTrl } = innovationApi.useGetTRLQuery()
  const { data: fetchedDirections } = innovationApi.useGetDirectionsQuery()
  const { data: fetchedTechnologies } = innovationApi.useGetTechnologiesQuery()
  const { data: fetchedFinanceSources } =
    innovationApi.useGetFinanceSourcesQuery()

  const [wasFinanced, setWasFinanced] = useState(true)

  return (
    <>
      <h3 className="fw-bold mt-5">Подробная информация о проекте</h3>
      {fetchedIndustries?.data && (
        <CheckboxRadioInput
          required
          title="Отрасль"
          idProp="id_industries"
          valueProp="industry_name"
          name="industries"
          items={fetchedIndustries?.data}
        />
      )}
      {fetchedMarkets?.data && (
        <CheckboxRadioInput
          title="Рынок НТИ"
          idProp="id_innovation_markets"
          valueProp="market_name"
          items={fetchedMarkets?.data}
          name="innovation_markets"
        />
      )}
      {fetchedTrl?.data && (
        <GroupSelect
          id="innovation_trl"
          name="id_trls"
          label="Уровень готовности технологии TRL"
          required
          options={fetchedTrl.data}
          optionIdProp="id_trls"
          optionValueProp="trl_name"
        />
      )}
      {fetchedDirections?.data && (
        <GroupSelect
          id="innovation_direction"
          name="id_innovation_directions"
          label="Направление"
          required
          options={fetchedDirections.data}
          optionIdProp="id_innovation_directions"
          optionValueProp="direction_name"
        />
      )}
      {fetchedTechnologies?.data && (
        <CheckboxRadioInput
          title="Технология"
          name="technologies"
          idProp="id_technologies"
          valueProp="technology_name"
          items={fetchedTechnologies.data}
          required
        />
      )}
      {defaultFields?.target_product?.active && (
        <TextAreaInput
          field={defaultFields.target_product}
          name="target_product"
        />
      )}
      <GroupInput
        id="innovation_target_product_photo"
        name="slider_images"
        labelText="Фото конечного продукта проекта"
        inputOptions={{
          className: "w-100 form-control",
          multiple: true,
          type: "file",
          accept: "image/png, image/jpeg",
        }}
      />
      {defaultFields?.start_date?.active && (
        <GroupInput
          id="innovation_start_date"
          name="start_date"
          labelText={defaultFields.start_date.label}
          tooltip={defaultFields.start_date.tooltip}
          inputOptions={{
            required: defaultFields.start_date.required,
            className: "w-auto",
            type: "date",
          }}
        />
      )}
      {defaultFields?.first_sale_date?.active && (
        <GroupInput
          id="innovation_first_sale_date"
          name="first_sale_date"
          labelText={defaultFields.first_sale_date.label}
          tooltip={defaultFields.first_sale_date.tooltip}
          inputOptions={{
            required: defaultFields.first_sale_date.required,
            className: "w-auto",
            type: "date",
          }}
        />
      )}
      {defaultFields?.budget?.active && (
        <GroupInput
          id="innovation_budget"
          name="budget"
          labelText={defaultFields.budget.label}
          tooltip={defaultFields.budget.tooltip}
          inputOptions={{
            required: defaultFields.budget.required,
            className: "w-auto",
            type: "number",
          }}
        />
      )}
      {defaultFields?.financing?.active && (
        <GroupSelect
          id="innovation_financing"
          name="financing"
          label={defaultFields.financing.label}
          required={defaultFields.financing.required}
          tooltip={defaultFields.financing.tooltip}
          selectClassName="w-auto"
          value={wasFinanced ? "1" : "0"}
          onChange={(e) => setWasFinanced(!!+e.target.value)}
          isBinary
        />
      )}
      {defaultFields?.fundraising?.active && wasFinanced && (
        <GroupInput
          id="innovation_fundraising"
          name="fundraising"
          labelText={defaultFields?.fundraising.label}
          tooltip={defaultFields?.fundraising.tooltip}
          inputOptions={{
            required: defaultFields.fundraising.required,
            className: "w-auto",
            type: "number",
          }}
        />
      )}
      {fetchedFinanceSources?.data &&
        defaultFields?.finance_sources?.active && (
          <CheckboxRadioInput
            title={defaultFields.finance_sources.label}
            required={defaultFields.finance_sources.required}
            tooltip={defaultFields.finance_sources.tooltip}
            name="finance_sources"
            idProp="id_finance_sources"
            valueProp="finance_sources_name"
            items={fetchedFinanceSources?.data}
          />
        )}
      {defaultFields?.expected_income?.active && (
        <GroupInput
          id="innovation_expected_income"
          name="expected_income"
          labelText={defaultFields.expected_income.label}
          tooltip={defaultFields.expected_income.tooltip}
          inputOptions={{
            required: defaultFields.expected_income.required,
            className: "w-auto",
            type: "number",
          }}
        />
      )}
      {defaultFields?.intellectual_property?.active && (
        <GroupSelect
          id="innovation_intellectual_property"
          name="intellectual_property"
          label={defaultFields.intellectual_property.label}
          required={defaultFields?.intellectual_property.required}
          tooltip={defaultFields.intellectual_property.tooltip}
          selectClassName="w-auto"
          isBinary
        />
      )}
      {defaultFields?.detailed_info?.active && (
        <TextAreaInput
          field={defaultFields.detailed_info}
          name="detailed_info"
        />
      )}
      <Card>
        <h5 className="card-title fw-bold mb-4">Цели проекта на год</h5>

        <TextAreaInput
          field={{
            field_name: "objective_1",
            label: "Цель 1",
            required: true,
          }}
          name="objectives_1"
        />

        <TextAreaInput
          field={{
            field_name: "objective_2",
            label: "Цель 2",
            required: true,
          }}
          name="objectives_2"
        />

        <TextAreaInput
          field={{
            field_name: "objective_3",
            label: "Цель 3",
            required: true,
          }}
          name="objectives_3"
        />
      </Card>
      {defaultFields?.expected_consumer?.active && (
        <TextAreaInput
          field={defaultFields.expected_consumer}
          name="expected_consumer"
        />
      )}
      {defaultFields?.expected_effect?.active && (
        <TextAreaInput
          field={defaultFields.expected_effect}
          name="expected_effect"
        />
      )}
      {defaultFields?.legal_entity?.active && (
        <GroupSelect
          id="innovation_legal_entity"
          selectClassName="w-auto"
          label={defaultFields.legal_entity.label}
          required={defaultFields.legal_entity.required}
          tooltip={defaultFields.legal_entity.tooltip}
          name="legal_entity"
          isBinary
        />
      )}
    </>
  )
}

export default DetailedInfo
