import { FC, PropsWithChildren } from "react"
import { InView } from "react-intersection-observer"

interface TableCardProps {
  title: string
  id: string
  onPrivateFieldIntersect: (
    inView: boolean,
    entry: IntersectionObserverEntry,
  ) => void
}

const TableCard: FC<PropsWithChildren<TableCardProps>> = ({
  children,
  title,
  id,
  onPrivateFieldIntersect,
}) => {
  return (
    <div className="col-4 col-md-4">
      <div>
        <InView as="table" onChange={onPrivateFieldIntersect} id={id}>
          <tbody>
            <tr>
              <td className="bl-i-h">{title}</td>
            </tr>
            <tr>
              <td className="bl-i-o">{children}</td>
            </tr>
          </tbody>
        </InView>
      </div>
    </div>
  )
}

export default TableCard
