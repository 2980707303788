import { useRef, useState } from "react"

import { innovationApi } from "../../services/innovationService"
import { useActionCreators, useTypedSelector } from "../../hooks/redux"
import FilterGroup from "../SidebarFilter/FilterGroup"
import TwoSideRange from "../SidebarFilter/TwoSideRange"

const InnovationFilter = () => {
  const actions = useActionCreators()

  const { data: industries } = innovationApi.useGetSearchIndustriesQuery()
  const { data: markets } = innovationApi.useGetSearchMarketsQuery()

  const filter = useTypedSelector((state) => state.filterReducer.innovations)
  const user = useTypedSelector((state) => state.userReducer.user)

  const [legalEntityExpanded, setLegalEntityExpanded] = useState(false)
  const [wasFinancedExpanded, setWasFinancedExpanded] = useState(false)

  const marketLengthRef = useRef(filter.innovation_markets.length)

  const handleSubscribe = () => {
    if (!user) {
      actions.setAuthModalVisible(true)
    } else {
      actions.setSubscribeModalVisible(true)
    }
  }

  return (
    <div className="col-xs-12 col-xxl-3 col-sm-12 col-md-3">
      <aside>
        <div className="filtr">
          <form className="mb-2">
            {industries && (
              <FilterGroup
                id="collapse1"
                initIsOpen
                title="Отрасль"
                items={industries}
                name="industries"
                reducerName="innovations"
              />
            )}

            <TwoSideRange title="TRL" initIsOpen />

            {markets && (
              <FilterGroup
                id="collapse2"
                initIsOpen={marketLengthRef.current > 0}
                title="Рынок NTI"
                items={markets}
                name="innovation_markets"
                reducerName="innovations"
              />
            )}

            <h5 className="mt-4">
              <a
                data-bs-toggle="collapse"
                href="#collapse3"
                role="button"
                aria-expanded="false"
                aria-controls="collapse3"
                onClick={() => setWasFinancedExpanded(!wasFinancedExpanded)}
              >
                Привлекал финансирование
                <i
                  className="fa fa-caret-down my-0"
                  aria-hidden="true"
                  style={{
                    display: "inline-block",
                    transform: wasFinancedExpanded ? "rotate(180deg)" : "",
                  }}
                ></i>
              </a>
            </h5>
            <div id="collapse3" className="collapse">
              <div className="form-check danet">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="check13"
                  checked={
                    filter.fundraising === null ? false : filter.fundraising
                  }
                  onChange={() =>
                    actions.setInnovationFilter({
                      id: "fundraising",
                      value: filter.fundraising ? null : true,
                    })
                  }
                />
                <label className="form-check-label" htmlFor="check13">
                  Да
                </label>
              </div>
              <div className="form-check danet">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="check14"
                  checked={
                    filter.fundraising === null ? false : !filter.fundraising
                  }
                  onChange={() =>
                    actions.setInnovationFilter({
                      id: "fundraising",
                      value:
                        filter.fundraising === null
                          ? false
                          : filter.fundraising
                          ? false
                          : null,
                    })
                  }
                />
                <label className="form-check-label" htmlFor="check14">
                  Нет
                </label>
              </div>
            </div>

            <h5 className="mt-4">
              <a
                data-bs-toggle="collapse"
                href="#collapse4"
                role="button"
                aria-expanded="false"
                aria-controls="collapse4"
                onClick={() => setLegalEntityExpanded(!legalEntityExpanded)}
              >
                Наличие юр. лица
                <i
                  className="fa fa-caret-down my-0"
                  aria-hidden="true"
                  style={{
                    display: "inline-block",
                    transform: legalEntityExpanded ? "rotate(180deg)" : "",
                  }}
                ></i>
              </a>
            </h5>
            <div id="collapse4" className="collapse">
              <div className="form-check danet">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="check15"
                  checked={
                    filter.legal_entity === null ? false : filter.legal_entity
                  }
                  onChange={() =>
                    actions.setInnovationFilter({
                      id: "legal_entity",
                      value: filter.legal_entity ? null : true,
                    })
                  }
                />
                <label className="form-check-label" htmlFor="check15">
                  Да
                </label>
              </div>
              <div className="form-check danet">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="check16"
                  checked={
                    filter.legal_entity === null ? false : !filter.legal_entity
                  }
                  onChange={() =>
                    actions.setInnovationFilter({
                      id: "legal_entity",
                      value:
                        filter.legal_entity === null
                          ? false
                          : filter.legal_entity
                          ? false
                          : null,
                    })
                  }
                />
                <label className="form-check-label" htmlFor="check16">
                  Нет
                </label>
              </div>
            </div>

            <button
              className="btn btn-primary mt-3 podpis"
              type="button"
              onClick={handleSubscribe}
            >
              Подписка на фильтр
            </button>
          </form>
        </div>
      </aside>
    </div>
  )
}

export default InnovationFilter
