import { FC } from "react"
import { Link } from "react-router-dom"

import { HOST } from "../../consts/hosts"
import defaultImage from "../../resources/default_image.png"
import { NEWS_PAGE_ROUTE } from "../../consts/routes"
import { News } from "../../types/news"

interface NewsCardProps {
  news: News
  handleCategoryClick: (tag: string) => void
}

const NewsCard: FC<NewsCardProps> = ({ news, handleCategoryClick }) => {
  return (
    <div className="col-xs-12 col-md-6 col-xl-4 mb-5 mt-2">
      <div className="article">
        <Link
          to={`${NEWS_PAGE_ROUTE}/${news.alt_name || news.id_news}`}
          className="stretched-link"
        >
          <div className="a-date float-start">
            <i className="fa fa-clock-o" aria-hidden="true" />{" "}
            {news.date_creation}
          </div>
          <div className="a-univ-container">
            {news.news_categories &&
              news.news_categories.map((category) => (
                <div
                  className="float-end a-univ"
                  key={category.id_news_categories}
                  onClick={() => handleCategoryClick(category.alt_name)}
                >
                  {category.title}
                </div>
              ))}
          </div>
          <div className="clr" />
          <div className="inst-img d-flex-img mt-4">
            <img
              src={news.logo ? `${HOST}/${news.logo}` : defaultImage}
              alt={news.title}
              className="d-block m-auto mh-100 mw-100"
            />
          </div>

          <h2 className="mt-4 mb-4">{news.title}</h2>

          <div className="clr" />
          <p dangerouslySetInnerHTML={{ __html: news.short_story || "" }} />
          <div className="clr" />
        </Link>
      </div>
    </div>
  )
}

export default NewsCard
