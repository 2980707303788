import { createApi } from "@reduxjs/toolkit/query/react"

import { IDefaultQuery, IDefaultResponse, ISubscribeQuery } from "../types/api"
import {
  Support,
  SupportDirection,
  SupportForm,
  SupportMember,
  Supports,
  SupportSave,
  SupportSearch,
} from "../types/supports"
import { UniversalFilterType } from "../types/common"

import { baseQuery } from "./baseQuery"

export const supportApi = createApi({
  reducerPath: "support",
  baseQuery,
  endpoints: (build) => ({
    getSupports: build.query<Supports, IDefaultQuery<SupportSearch>>({
      query: ({ page, search }) => ({
        url: `/getsupports/${page}`,
        body: search ? { search } : {},
        method: "POST",
      }),
      transformResponse(response: IDefaultResponse<Support[]>) {
        return {
          list: response.data ?? [],
          count: response.count || 0,
          min_amount: response.min_amount || 0,
          max_amount: response.max_amount || 0,
        }
      },
    }),
    getSupport: build.query<IDefaultResponse<Support>, number>({
      query: (id) => ({
        url: `/getsupport/${id}`,
      }),
    }),
    getSupportStatuses: build.query<IDefaultResponse<string[]>, void>({
      query: () => ({
        url: "/getsupportstatuses",
      }),
    }),
    getSupportForms: build.query<UniversalFilterType[], void>({
      query: () => ({
        url: "/getsupportforms",
      }),
      transformResponse(response: IDefaultResponse<SupportForm[]>) {
        return (
          response.data?.map((form) => ({
            id: form.id_support_forms,
            title: form.support_form_name,
          })) ?? []
        )
      },
    }),
    getSupportDirections: build.query<UniversalFilterType[], void>({
      query: () => ({
        url: "/getsupportdirections",
      }),
      transformResponse(response: IDefaultResponse<SupportDirection[]>) {
        return (
          response.data?.map((form) => ({
            id: form.id_support_directions,
            title: form.support_direction_name,
          })) ?? []
        )
      },
    }),
    getSupportMembers: build.query<UniversalFilterType[], void>({
      query: () => ({
        url: "/getsupportmembers",
      }),
      transformResponse(response: IDefaultResponse<SupportMember[]>) {
        return (
          response.data?.map((form) => ({
            id: form.id_support_members,
            title: form.support_members_name,
          })) ?? []
        )
      },
    }),
    // getSupportReasons: build.query<IDefaultResponse<SupportReason[]>, void>({
    //   query: () => ({
    //     url: "/getsupportreasons",
    //   }),
    // }),
    saveSupport: build.mutation<void, SupportSave>({
      query: (support) => ({
        url: "/sendsupport",
        body: support,
      }),
    }),
    deleteLogo: build.mutation<void, SupportSave>({
      query: (id) => ({
        url: "/deletesupportlogo",
        body: { id_supports: id },
      }),
    }),
    deleteSupports: build.mutation<void, string[]>({
      query: (idArray) => ({
        url: "/deletesupports",
        body: {
          list: idArray.map((id) => ({ id_supports: id })),
        },
      }),
    }),
    subscribe: build.mutation<void, ISubscribeQuery>({
      query: ({ url, email, name }) => ({
        url: "/sendsubscribesearch",
        body: {
          url,
          email,
          fio: name,
        },
        method: "POST",
      }),
    }),
  }),
})
