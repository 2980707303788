import { createApi } from "@reduxjs/toolkit/query/react"

import { News, NewsCategory, NewsList, NewsSearch } from "../types/news"
import { IDefaultQuery, IDefaultResponse } from "../types/api"

import { baseQuery } from "./baseQuery"

export const newsApi = createApi({
  reducerPath: "news",
  baseQuery,
  endpoints: (build) => ({
    getNews: build.query<NewsList, IDefaultQuery<NewsSearch>>({
      query: ({ page, search }) => ({
        url: `/getnews/${page}`,
        body: search ? { search } : {},
        method: "POST",
      }),
      transformResponse(response: IDefaultResponse<News[]>) {
        return {
          list: response.data ?? [],
          count: response.count || 0,
        }
      },
    }),
    getNewsDetailed: build.query<IDefaultResponse<News>, string>({
      query: (id) => ({
        url: `/getonenews/${id}`,
      }),
    }),
    getNewsCategories: build.query<IDefaultResponse<NewsCategory[]>, void>({
      query: () => ({
        url: "/getnewscategories",
      }),
    }),
  }),
})
