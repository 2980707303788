import { useState, FormEvent } from "react"
import { useNavigate } from "react-router-dom"

import { SUPPORTS_PAGE_ROUTE } from "../../consts/routes"

const HeaderForm = () => {
  const [search, setSearch] = useState("")
  const navigate = useNavigate()

  const onSubmit = (e: FormEvent) => {
    e.preventDefault()
    navigate(`${SUPPORTS_PAGE_ROUTE}?search=${search}`)
  }

  return (
    <form className="search" onSubmit={onSubmit}>
      <input
        type="text"
        placeholder="Поиск мер поддержки..."
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <button type="submit">
        <i className="fa fa-search" aria-hidden="true" />
      </button>
    </form>
  )
}

export default HeaderForm
