import { FC } from "react"

import { Innovation } from "../../types/innovations"
import DownloadIcon from "../../resources/download.png"
import { HOST } from "../../consts/hosts"

import InfoLabels from "./InfoLabels"

interface LogoFormsProps {
  innovation: Innovation
  onPrivateFieldIntersect: (
    inView: boolean,
    entry: IntersectionObserverEntry,
  ) => void
}

const LogoForms: FC<LogoFormsProps> = ({ innovation }) => {
  return (
    <>
      <div className="ki-img col-12 col-md-3 ki-img-down">
        {innovation.logo && (
          <img src={HOST + innovation.logo} alt={innovation.innovation_name} />
        )}

        <InfoLabels innovation={innovation} />

        <div className="homebtn mt-1 podr-vitr">
          {innovation.presentation && (
            <a
              href={HOST + innovation.presentation}
              target="_blank"
              rel="noreferrer"
            >
              <img className="arrow2" src={DownloadIcon} alt="" />
              Скачать презентацию проекта
            </a>
          )}
        </div>
      </div>

      <div className="ki-img col-12 ki-img-up">
        {innovation.logo && (
          <img src={HOST + innovation.logo} alt={innovation.innovation_name} />
        )}
      </div>
    </>
  )
}

export default LogoForms
