import { FC } from "react"
import { InView } from "react-intersection-observer"

import { Innovation } from "../../types/innovations"
import { HOST } from "../../consts/hosts"

import InfoLabels from "./InfoLabels"

interface HeaderDescriptionProps {
  innovation: Innovation
  onPrivateFieldIntersect: (
    inView: boolean,
    entry: IntersectionObserverEntry,
  ) => void
}

const HeaderDescription: FC<HeaderDescriptionProps> = ({
  innovation,
  onPrivateFieldIntersect,
}) => {
  const getEmbedYoutubeLink = (link: string) => {
    let videoId = ""

    if (link.includes("youtu.be")) {
      videoId = link.split("/").at(-1) ?? ""
    } else if (link.includes("watch?")) {
      const paramsArray = Array.from(new URLSearchParams(link.split("?")[1]))

      videoId = paramsArray.find((param) => param[0] === "v")?.at(-1) ?? ""
    }

    return videoId ? `https://youtube.com/embed/${videoId}` : ""
  }

  return (
    <div className="ki-content col-12 col-md-8">
      <div className="clr"></div>
      <h1 className="mt-0 mb-2">{innovation.innovation_name}</h1>
      {innovation.site && (
        <InView
          as="div"
          onChange={onPrivateFieldIntersect}
          className="a-forma mb-2"
          id="site"
        >
          {innovation.site.includes("░") ? (
            <span>
              <i className="fa fa-globe" aria-hidden="true"></i>
              {innovation.site}
            </span>
          ) : (
            <a href={innovation.site} target="_blank" rel="noreferrer">
              <i className="fa fa-globe" aria-hidden="true"></i>
              {innovation.site}
            </a>
          )}
        </InView>
      )}
      <div className="clr"></div>

      <p className="mb-0">
        Отрасль:{" "}
        <b>
          {innovation.industries
            .map((industry) => industry.industry_name)
            .join(", ")}
        </b>
      </p>
      <p className="mt-0 pt-0">
        Технология:{" "}
        <b>
          {innovation.technologies
            .map((technology) => technology.technology_name)
            .join(", ")}
        </b>
      </p>

      <div className="ki-img-up mb-4">
        <InfoLabels innovation={innovation} />
      </div>

      {innovation.description && (
        <InView as="p" onChange={onPrivateFieldIntersect} id="description">
          {innovation.description}
        </InView>
      )}

      {innovation.keywords && (
        <InView
          as="p"
          onChange={onPrivateFieldIntersect}
          className="kluchsl"
          id="keywords"
        >
          {innovation.keywords.split(",").map((keyword, index) => (
            <span key={index}>#{keyword}</span>
          ))}
        </InView>
      )}

      {innovation.presentation && (
        <div className="homebtn mt-5 podr-vitr ki-img-up">
          <a href={HOST + innovation.presentation}>
            <img className="arrow2" src="/download.png" alt="" />
            Скачать презентацию проекта
          </a>
        </div>
      )}

      {innovation.video_presentation_link && (
        <div>
          <h2 className="mt-5 mb-4">Видеопрезентация</h2>
          <iframe
            src={getEmbedYoutubeLink(innovation.video_presentation_link)}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
      )}
    </div>
  )
}

export default HeaderDescription
