import { FC, useEffect, useRef } from "react"

interface TooltipProps {
  tooltip: string
}

const Tooltip: FC<TooltipProps> = ({ tooltip }) => {
  const tooltipRef = useRef<HTMLButtonElement>(null)

  const onShow = () => {
    ;(window as any).bootstrap.Tooltip.getOrCreateInstance(
      tooltipRef.current,
    ).show()
  }

  const onHide = () => {
    ;(window as any).bootstrap.Tooltip.getOrCreateInstance(
      tooltipRef.current,
    ).hide()
  }

  useEffect(() => {
    const button = tooltipRef.current

    if (!button) return

    button.addEventListener("mouseenter", onShow)
    button.addEventListener("mouseleave", onHide)

    return () => {
      button.removeEventListener("mouseenter", onShow)
      button.removeEventListener("mouseleave", onHide)
    }
  }, [])

  return (
    <button
      type="button"
      className="btn btn-outline-dark p-0 tooltip-btn"
      data-bs-toggle="tooltip"
      data-bs-placement="top"
      title={tooltip}
      ref={tooltipRef}
    >
      ?
    </button>
  )
}

export default Tooltip
