import {
  InnovationAdditionalField,
  InnovationDefaultField,
} from "../types/innovations"

import { checkRequiredField } from "./checkRequiredField"
import { isFieldRequired } from "./isFieldRequired"
import { isLink } from "./isLink"
import { isFullName } from "./isFullName"
import { isPositiveInteger } from "./isPositiveInteger"

export function validateCreateInnovationForm(
  form: HTMLFormElement,
  formValues: Record<string, FormDataEntryValue>,
  defaultFields: Record<string, InnovationDefaultField>,
  additionalFields: InnovationAdditionalField[],
) {
  if (!defaultFields) return

  const elements = form.elements

  function checkRequiredFieldByName(
    elementName: string,
    options?: {
      customStringCheck?: (str: string) => boolean
      isCheckboxMultiple?: boolean
    },
  ) {
    try {
      return checkRequiredField(formValues, elements, elementName, options)
    } catch (e) {
      console.log(elementName, (e as Error).message)
    }
  }

  // Short info block validation
  checkRequiredFieldByName("innovation_name")

  checkRequiredFieldByName("wallpaper")

  isFieldRequired(defaultFields.description) &&
    checkRequiredFieldByName("description")

  isFieldRequired(defaultFields.keywords) &&
    checkRequiredFieldByName("keywords")

  checkRequiredFieldByName("id_stages")

  isFieldRequired(defaultFields.site) &&
    checkRequiredFieldByName("site", {
      customStringCheck: isLink,
    })

  // Team block validation
  checkRequiredFieldByName("member_0_fio", { customStringCheck: isFullName })
  checkRequiredFieldByName("member_0_position")
  checkRequiredFieldByName("member_0_degree")

  isFieldRequired(defaultFields.count_members) &&
    checkRequiredFieldByName("count_members", {
      customStringCheck: (amount: string) => {
        return /^\d+$/.test(amount) && isPositiveInteger(+amount)
      },
    })

  let member = 1

  while (true) {
    if (!elements.namedItem(`member_${member}_fio`)) break

    checkRequiredFieldByName(`member_${member}_fio`, {
      customStringCheck: isFullName,
    })
    checkRequiredFieldByName(`member_${member}_position`)
    checkRequiredFieldByName(`member_${member}_degree`)

    member++
  }

  // Detailed info block validation
  checkRequiredFieldByName("industries", {
    isCheckboxMultiple: true,
  })

  checkRequiredFieldByName("id_trls")

  checkRequiredFieldByName("id_innovation_directions")

  checkRequiredFieldByName("technologies", {
    isCheckboxMultiple: true,
  })

  isFieldRequired(defaultFields.target_product) &&
    checkRequiredFieldByName("target_product")

  isFieldRequired(defaultFields.start_date) &&
    checkRequiredFieldByName("start_date")

  isFieldRequired(defaultFields.first_sale_date) &&
    checkRequiredFieldByName("first_sale_date")

  isFieldRequired(defaultFields.budget) &&
    checkRequiredFieldByName("budget", {
      customStringCheck: (amount: string) => {
        return /^\d+$/.test(amount) && isPositiveInteger(+amount)
      },
    })

  isFieldRequired(defaultFields.financing) &&
    checkRequiredFieldByName("finance_sources", {
      isCheckboxMultiple: true,
    })

  defaultFields.financing.active &&
    Number(formValues.financing) === 1 &&
    isFieldRequired(defaultFields.fundraising) &&
    checkRequiredFieldByName("fundraising", {
      customStringCheck: (amount: string) => {
        return /^\d+$/.test(amount) && isPositiveInteger(+amount)
      },
    })

  isFieldRequired(defaultFields.expected_income) &&
    checkRequiredFieldByName("expected_income", {
      customStringCheck: (amount: string) => {
        return /^\d+$/.test(amount) && isPositiveInteger(+amount)
      },
    })

  isFieldRequired(defaultFields.intellectual_property) &&
    checkRequiredFieldByName("intellectual_property")

  isFieldRequired(defaultFields.detailed_info) &&
    checkRequiredFieldByName("detailed_info")

  checkRequiredFieldByName("objectives_1")
  checkRequiredFieldByName("objectives_2")
  checkRequiredFieldByName("objectives_3")

  isFieldRequired(defaultFields.expected_consumer) &&
    checkRequiredFieldByName("expected_consumer")

  isFieldRequired(defaultFields.expected_effect) &&
    checkRequiredFieldByName("expected_effect")

  isFieldRequired(defaultFields.legal_entity) &&
    checkRequiredFieldByName("legal_entity")

  // Demand block validation
  isFieldRequired(defaultFields.required_resources) &&
    checkRequiredFieldByName("required_resources")

  let vacancy = 0

  while (true) {
    if (!elements.namedItem(`vacancy_${vacancy}_name`)) break

    checkRequiredFieldByName(`vacancy_${vacancy}_name`)
    checkRequiredFieldByName(`vacancy_${vacancy}_description`)
    checkRequiredFieldByName(`vacancy_${vacancy}_amount`, {
      customStringCheck: (amount: string) => {
        return /^\d+$/.test(amount) && isPositiveInteger(+amount)
      },
    })

    vacancy++
  }

  // Additional info block validation
  additionalFields.forEach((field) => {
    if (!field.required) return

    const fieldType = field.id_field_types

    if (fieldType === 1) {
      checkRequiredFieldByName(field.field_name)

      return
    }

    if (fieldType === 2 || fieldType === 3) {
      checkRequiredFieldByName(field.field_name, {
        isCheckboxMultiple: true,
      })
    }
  })

  // Files block validation
  isFieldRequired(defaultFields.presentation) &&
    checkRequiredFieldByName("presentation")

  isFieldRequired(defaultFields.video_presentation_link) &&
    checkRequiredFieldByName("video_presentation_link", {
      customStringCheck: isLink,
    })

  // Check for invalid fields
  const invalidFields = document.querySelectorAll(".field-required")

  if (invalidFields.length) {
    invalidFields[0].scrollIntoView({ behavior: "smooth", block: "center" })

    return false
  } else {
    return true
  }
}
