import { createApi } from "@reduxjs/toolkit/query/react"

import { Region } from "../types/common"
import { IDefaultResponse } from "../types/api"

import { baseQuery } from "./baseQuery"

export const miscApi = createApi({
  reducerPath: "misc",
  baseQuery,
  endpoints: (build) => ({
    getRegions: build.query<IDefaultResponse<Region[]>, void>({
      query: () => "/getregions",
      transformResponse(response: IDefaultResponse<Region[]>) {
        return {
          ...response,
          data: response.data
            ? [{ id_regions: 0, region_name: "Не указан" }, ...response.data]
            : [],
        }
      },
    }),
  }),
})
