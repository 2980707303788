import { FC } from "react"
import { toast } from "react-toastify"

import { useActionCreators, useTypedSelector } from "../../hooks/redux"
import { userApi } from "../../services/userService"

interface UnsubscribeModalProps {
  subId: number
}

const UnsubscribeModal: FC<UnsubscribeModalProps> = ({ subId }) => {
  const actions = useActionCreators()

  const [unsubscribe, { isLoading }] = userApi.useDeleteSubscriptionMutation()

  const isVisible = useTypedSelector(
    (state) => state.modalReducer.isUnsubscribeModalVisible,
  )

  const onClose = () => actions.setUnsubscribeModalVisible(false)

  const onUnsubscribe = () => {
    unsubscribe(subId).then(() => {
      actions.setUnsubscribeModalVisible(false)
      toast.success("Вы успешно отписались")
    })
  }

  return (
    <div id="modal" style={{ display: isVisible ? "block" : "" }}>
      <div className="overlay-modal" onClick={onClose}></div>
      <div className="aside-b subscribe">
        <h4>Подтвердите отписку</h4>
        <div className="form-text mt-2 mb-0">
          <p>Вы действительно хотите отписаться от этого фильтра?</p>
          {!isLoading && (
            <button
              className="btn btn-primary mt-2 ms-0"
              onClick={onUnsubscribe}
            >
              Подтвердить
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default UnsubscribeModal
