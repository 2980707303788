import { useCallback, useEffect, useMemo } from "react"
import { useLocation, useSearchParams } from "react-router-dom"
import "rc-slider/assets/index.css"

import Layout from "../components/Layout"
import SupportFilter from "../components/ItemsFilter/SupportFilter"
import SupportCard from "../components/Cards/SupportCard"
import { useActionCreators, useTypedSelector } from "../hooks/redux"
import { supportApi } from "../services/supportService"
import { useDebounce } from "../hooks/useDebounce"
import Paginate from "../components/Paginate"
import { getObjectFromQuery } from "../utils/getObjectFromQuery"
import SubscribeModal from "../components/Modals/SubscribeModal"

const SupportsPage = () => {
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const actions = useActionCreators()

  const [getSupports, { data: supports }] = supportApi.useLazyGetSupportsQuery()

  const filter = useTypedSelector((state) => state.filterReducer.supports)

  const debouncedFilter = useDebounce(filter, 500)

  const initialPage = useMemo(() => {
    const page = searchParams.get("page")

    if (page === null) return 1

    return +page ?? 1
  }, [searchParams])

  const pageCount = useMemo(
    () => (supports ? Math.ceil(supports.count / filter.page_size) : 0),
    [filter.page_size, supports],
  )

  const onPageChange = useCallback(
    (e: { selected: number }) =>
      actions.setSupportFilter({ id: "page", value: e.selected + 1 }),
    [actions],
  )

  useEffect(() => {
    setSearchParams({
      forms: filter.forms.join(","),
      directions: filter.directions.join(","),
      members: filter.members.join(","),
      min_amount: `${filter.min_amount}`,
      max_amount: `${filter.max_amount}`,
      search: filter.search,
      sort: filter.sort,
      page: `${filter.page}`,
      page_size: `${filter.page_size}`,
    })
  }, [filter, location.search, searchParams, setSearchParams])

  useEffect(() => {
    getSupports({
      page: debouncedFilter.page,
      search: {
        sort: debouncedFilter.sort,
        maxelem: debouncedFilter.page_size,
        support_forms: debouncedFilter.forms.join(","),
        support_members: debouncedFilter.members.join(","),
        support_directions: debouncedFilter.directions.join(","),
        start_amount: debouncedFilter.min_amount,
        end_amount: debouncedFilter.max_amount,
        support_name: debouncedFilter.search,
      },
    })
  }, [debouncedFilter, getSupports])

  useEffect(() => {
    if (!supports) return

    if (filter.totalMaxAmount || filter.totalMinAmount) return

    actions.setSupportFilter({
      id: "totalMinAmount",
      value: supports.min_amount,
    })
    actions.setSupportFilter({
      id: "totalMaxAmount",
      value: supports.max_amount,
    })
  }, [actions, filter.totalMaxAmount, filter.totalMinAmount, supports])

  useEffect(() => {
    actions.setSupportFilterFromUrl(
      getObjectFromQuery(location.search, ["forms", "directions", "members"]),
    )
  }, [actions, location.search])

  return (
    <Layout title="Меры поддержки - Центр трансфера технологий">
      <div className="row">
        <h1 className="h1-st mb-4 mt-4">Меры поддержки</h1>
        <SupportFilter />

        <div className="col-xs-12 col-sm-12 col-lg-8 offset-lg-1 col-xl-8 offset-xl-1 col-xxl-9 offset-xxl-1 col-md-8">
          <section className="mb-4">
            <div className="sort mb-4">
              <div className="form-group">
                <select
                  className="form-control"
                  value={filter.sort}
                  onChange={(e) =>
                    actions.setSupportFilter({
                      id: "sort",
                      value: e.target.value,
                    })
                  }
                >
                  <option value="support_name">По названию ↑</option>
                  <option value="support_name-inverse">По названию ↓</option>
                  <option value="amount">По сумме ↑</option>
                  <option value="amount-inverse">По сумме ↓</option>
                  <option value="date_creation">По дате публикации ↑</option>
                  <option value="date_creation-inverse">
                    По дате публикации ↓
                  </option>
                  <option value="date_end">По дате окончания ↑</option>
                  <option value="date_end-inverse">По дате окончания ↓</option>
                </select>
              </div>
            </div>

            <div className="row">
              {supports?.list.map((support) => (
                <SupportCard support={support} key={support.id_supports} />
              ))}

              <Paginate
                initPage={initialPage - 1}
                pageCount={pageCount || 1}
                onPageChange={onPageChange}
              />
            </div>
          </section>
        </div>
      </div>
      <SubscribeModal />
    </Layout>
  )
}

export default SupportsPage
