import { InnovationMember } from "../types/innovations"

import { readFileAsBase64 } from "./readFileAsBase64"

type MemberWithoutId = Omit<InnovationMember, "id_members">

export async function getMembers(): Promise<MemberWithoutId[]> {
  const memberCards = document.querySelectorAll(".member")
  const members: MemberWithoutId[] = []

  const keys: ["fio", "position", "degree", "logo"] = [
    "fio",
    "position",
    "degree",
    "logo",
  ]

  for (let i = 0; i < memberCards.length; i++) {
    const card = memberCards[i]

    const inputs = card.querySelectorAll("input[name]")
    const inputValues: MemberWithoutId = {} as MemberWithoutId

    for (let j = 0; j < inputs.length; j++) {
      const element = inputs[j]

      const input = element as HTMLInputElement
      const value = keys[j]

      if (value === "logo") {
        if (!input.files?.length) {
          inputValues.logo = null

          break
        }

        const file = input.files[0]

        inputValues[value] = await readFileAsBase64(file)

        break
      }

      inputValues[value] = input.value
    }

    inputValues.leader = i === 0

    members.push(inputValues)
  }

  return members
}
