import { FormEvent } from "react"
import { Link } from "react-router-dom"

import { useActionCreators, useTypedSelector } from "../../hooks/redux"
import { userApi } from "../../services/userService"
import TextForm from "../ModalForms/TextForm"
import { PERSONAL_ACCOUNT_ROUTE } from "../../consts/routes"

const SubscribeModal = () => {
  const actions = useActionCreators()

  const [sendSubscribe, { data: subscribeResponse, isLoading }] =
    userApi.useSubscribeToFilterMutation()

  const isVisible = useTypedSelector(
    (state) => state.modalReducer.isSubscribeModalVisible,
  )
  const userId = useTypedSelector((state) => state.userReducer.user?.id)

  const onClose = () => actions.setSubscribeModalVisible(false)

  const onSubscribe = (e: FormEvent) => {
    e.preventDefault()

    if (!userId) return

    sendSubscribe({
      id: userId,
      url: window.location.href,
    })
  }

  return (
    <div id="modal" style={{ display: isVisible ? "block" : "" }}>
      <div className="overlay-modal" onClick={onClose}></div>
      <div className="aside-b subscribe">
        {subscribeResponse?.status === "successful" ? (
          <TextForm title="Подписка подтверждена">
            <p>
              Вы успешно подписались на фильтр, проверить все ваши подписки
              можете в <Link to={PERSONAL_ACCOUNT_ROUTE}>Личном кабинете</Link>
            </p>
          </TextForm>
        ) : (
          <form onSubmit={onSubscribe}>
            <h4>Подтвердите подписку</h4>
            <div className="form-text mt-2 mb-0">
              <p>Вы действительно хотите подписаться на этот фильтр?</p>
              {!isLoading && (
                <button type="submit" className="btn btn-primary mt-2 ms-0">
                  Подтвердить
                </button>
              )}
            </div>
          </form>
        )}
      </div>
    </div>
  )
}

export default SubscribeModal
