import { FC, PropsWithChildren } from "react"

import HeaderMenu from "./Header/HeaderMenu"
import Footer from "./Footer"
import HeadMetaComponent from "./HeadMetaComponent"

interface LayoutProps {
  title: string
  description?: string
  keywords?: string
}

const Layout: FC<PropsWithChildren<LayoutProps>> = ({
  title,
  keywords,
  description,
  children,
}) => {
  return (
    <>
      <HeadMetaComponent
        title={title}
        keywords={keywords}
        description={description}
      />
      <div className="container-fluid">
        <div className="container">
          <HeaderMenu />
          {children}
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Layout
