import { FC, useEffect, useMemo, useRef, useState } from "react"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"

import { isFullName } from "../../utils/isFullName"
import Spinner from "../Spinner"
import { authApi } from "../../services/authService"
import Arrow from "../Icons/Arrow"
import { badFio, personalInfoNotChecked } from "../../consts/toastErrors"

import TextForm from "./TextForm"

interface RegistrationFormProps {
  email: string
  onBack: () => void
}

const RegistrationForm: FC<RegistrationFormProps> = ({ email, onBack }) => {
  const [sendRegister, { isLoading, data: registerResponse }] =
    authApi.useSendRegisterMutation()

  const [name, setName] = useState("")
  const [isChecked, setIsChecked] = useState(false)

  const inputRef = useRef<HTMLInputElement | null>(null)

  const isNameValid = useMemo(() => isFullName(name), [name])

  const sendRegistration = async () => {
    if (!isNameValid) {
      toast.warning(badFio)

      return
    }

    if (!isChecked) {
      toast.warning(personalInfoNotChecked)

      return
    }

    sendRegister({ email, fio: name })
  }

  useEffect(() => {
    inputRef.current && inputRef.current?.focus()
  }, [])

  useEffect(() => {
    if (registerResponse?.status === "error") {
      toast.error("Произошла ошибка, попробуйте еще раз")
    }
  }, [registerResponse?.status])

  if (registerResponse?.status === "successful") {
    return (
      <TextForm
        title="Спасибо"
        children={"На вашу почту отправлена ссылка активации, перейдите по ней"}
      />
    )
  }

  return (
    <form>
      <h4>Регистрация</h4>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <button className="btn-back" onClick={onBack}>
            <Arrow />
          </button>
          <div className="form mb-2">
            <input
              type="email"
              className="form-control"
              placeholder="Email"
              disabled
              value={email}
            />
          </div>
          <div className="form mb-2">
            <input
              type="text"
              className="form-control"
              placeholder="ФИО"
              value={name}
              ref={inputRef}
              onChange={(e) => setName(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" && sendRegistration()}
            />
          </div>
          <div className="clr"></div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="flexCheckDefault"
              checked={isChecked}
              onChange={(e) => setIsChecked(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="flexCheckDefault">
              Согласен на{" "}
              <Link to="/rules" target="_blank">
                обработку персональных данных
              </Link>
            </label>
          </div>
          <button
            type="button"
            onClick={sendRegistration}
            className="btn btn-primary my-2"
          >
            Зарегистрировать
          </button>
        </>
      )}
    </form>
  )
}

export default RegistrationForm
