import { useEffect } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"

import { HOST } from "../consts/hosts"
import defaultImage from "../resources/default_image.png"
import arrow from "../resources/arrow.png"
import { instituteApi } from "../services/instituteService"
import {
  INSTITUTES_PAGE_ROUTE,
  NOT_FOUND_PAGE_ROUTE,
  SUPPORTS_PAGE_ROUTE,
} from "../consts/routes"
import Layout from "../components/Layout"
import { useActionCreators, useTypedSelector } from "../hooks/redux"

const SUPPORT_PAGE = SUPPORTS_PAGE_ROUTE.slice(0, -1)

const InstitutePage = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const actions = useActionCreators()

  const [fetchInstitute, { data: institute }] =
    instituteApi.useLazyGetInstituteQuery()
  const [fetchSupports, { data: supports }] =
    instituteApi.useLazyGetSupportsForInstituteQuery()
  const [fetchSupportForms, { data: supportForms }] =
    instituteApi.useLazyGetSupportsFormsForInstituteQuery()

  const user = useTypedSelector((state) => state.userReducer.user)

  const handleLinkClick = (id: number) => {
    actions.resetState()
    actions.setInstitutesFilter({ id: "forms", value: id })
  }

  useEffect(() => {
    if (id === undefined || !+id) {
      navigate(NOT_FOUND_PAGE_ROUTE)
    }

    if (id) {
      fetchInstitute(+id).then(({ data }) => {
        if (data?.data === null) {
          navigate(NOT_FOUND_PAGE_ROUTE)
        }
      })
      fetchSupports(+id)
      fetchSupportForms(+id)
    }
  }, [fetchInstitute, fetchSupportForms, fetchSupports, id, navigate])

  return (
    <Layout
      title={
        institute?.data?.title_meta ||
        "Институты поддержки - Центр трансфера технологий"
      }
      description={institute?.data?.description_meta}
      keywords={institute?.data?.keywords_meta}
    >
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-xxl-12">
          <section className="mt-4 kartochka ki">
            <article className="mb-4">
              <div className="article">
                {user && [1, 5].includes(user.groups_id_groups) && (
                  <a
                    className="btn btn-primary d-inline-block mb-4 col-8 col-sm-6 col-md-4 col-xxl-3"
                    href={`https://admin.permctt.ru/institutes/${id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Редактировать
                  </a>
                )}

                <div className="ki-img col-12 ki-img-up position-relative mb-4">
                  <div className="inst-img">
                    <img
                      src={
                        institute?.data?.logo
                          ? `${HOST}/${institute.data.logo}`
                          : defaultImage
                      }
                      alt={institute?.data?.institute_name}
                    />
                  </div>
                </div>

                <div className="ki-content col-12 col-md-8">
                  <div className="clr" />
                  <h1 className="mt-2 mb-4">
                    {institute?.data?.institute_name}
                  </h1>
                  {institute?.data?.institution_forms &&
                    institute.data.institution_forms.map((form) => (
                      <div
                        className="a-firma k-border mb-1"
                        key={form.id_institution_forms}
                      >
                        {form.institution_form_name}
                      </div>
                    ))}

                  <div className="clr" />
                  {!!institute?.data?.site && (
                    <div className="a-forma mb-4">
                      <a
                        href={institute.data.site}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fa fa-globe" aria-hidden="true" />
                        {institute.data.site}
                      </a>
                    </div>
                  )}

                  <div className="clr" />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: institute?.data?.description || "",
                    }}
                  />

                  {supportForms?.data?.length && (
                    <div>
                      <h2 className="mt-5 mb-4">Формы поддержки</h2>
                      {supportForms.data.map((form) => (
                        <Link
                          to={INSTITUTES_PAGE_ROUTE}
                          key={form.id_support_forms}
                          onClick={() => handleLinkClick(form.id_support_forms)}
                        >
                          <div className="a-firma k-border mb-4">
                            {form.support_form_name}
                          </div>
                        </Link>
                      ))}
                    </div>
                  )}

                  {supports?.data?.length && (
                    <h2 className="mt-4 mb-4">Актуальные меры поддержки</h2>
                  )}
                </div>
                <div className="ki-content2 col-12 col-lg-8">
                  {supports &&
                    supports.data?.map((support) => (
                      <article className="mb-5 mt-1" key={support.id_supports}>
                        <div className="article">
                          <Link
                            to={`${SUPPORT_PAGE}/${support.id_supports}`}
                            className="stretched-link"
                          >
                            <div className="a-date float-start">
                              <i className="fa fa-clock-o" aria-hidden="true" />{" "}
                              {support.date_end_dd}
                            </div>
                            {support?.support_forms &&
                              support.support_forms.map((form) => (
                                <div
                                  className="a-forma float-end"
                                  key={form.id_support_forms}
                                >
                                  {form.support_form_name}
                                </div>
                              ))}
                            <div className="clr" />
                            <h2 className="mt-2 mb-4">
                              {support.support_name_manual ||
                                support.support_name}
                            </h2>
                            <div className="float-end">
                              <div className="a-cena mb-3">
                                <b>{support.amount}</b>
                                <u>тыс. руб.</u>
                              </div>
                              <div className="a-srok float-end">
                                <i
                                  className="fa fa-clock-o"
                                  aria-hidden="true"
                                />
                                <u>
                                  {support?.remained_count}{" "}
                                  {support?.remained_days}
                                </u>
                                <img
                                  className="arrow"
                                  src={arrow}
                                  alt="стрелка"
                                />
                              </div>
                            </div>
                            <div className="clr" />
                          </Link>
                        </div>
                        <div className="float-start float-start-url">
                          <div className="a-firma">
                            <a href="#" className="stretched-link">
                              {institute?.data?.institute_name}
                            </a>
                          </div>
                        </div>
                      </article>
                    ))}
                </div>

                <div className="ki-img col-12 col-md-3 ki-img-down">
                  <div className="inst-img">
                    <img
                      src={
                        institute?.data?.logo
                          ? `${HOST}/${institute.data.logo}`
                          : defaultImage
                      }
                      alt={institute?.data?.institute_name}
                    />
                  </div>
                </div>
              </div>
            </article>
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default InstitutePage
