import { FC, FormEvent, useMemo, useState } from "react"
import { toast } from "react-toastify"

import { isName } from "../utils/isName"
import { isEmail } from "../utils/isEmail"
import { userApi } from "../services/userService"

interface FeedbackProps {
  isFooter?: boolean
}

const Feedback: FC<FeedbackProps> = ({ isFooter }) => {
  const [sendFeedback] = userApi.useSendFeedbackMutation()

  const [form, setForm] = useState({
    name: "",
    email: "",
    subject: "",
    text: "",
  })

  const isFormValid = useMemo(() => {
    return (
      isName(form.name) &&
      isEmail(form.email) &&
      form.subject.length > 0 &&
      form.text.length > 0
    )
  }, [form])

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()

    if (isFormValid) {
      setForm({
        name: "",
        email: "",
        subject: "",
        text: "",
      })

      return sendFeedback({
        name: form.name,
        email: form.email,
        message: form.text,
        company: form.subject,
      }).then(() => toast.success("Ваше обращение отправлено!"))
    }

    if (!isName(form.name)) {
      return toast.error("Проверьте корректность ФИО")
    }

    if (!isEmail(form.email)) {
      return toast.error("Проверьте корректность Email")
    }

    if (!form.subject) {
      return toast.error("Укажите тему")
    }

    if (!form.text) {
      return toast.error("Укажите сообщение")
    }
  }

  if (isFooter) {
    return (
      <form onSubmit={handleSubmit}>
        <h5>Обратная связь</h5>
        <div className="col-12 col-lg-6">
          <div className="form mb-2">
            <input
              type="text"
              className="form-control"
              placeholder="Имя"
              onChange={(e) => setForm({ ...form, name: e.target.value })}
            />
          </div>
          <div className="form mb-2">
            <input
              type="email"
              className="form-control"
              placeholder="Email"
              onChange={(e) => setForm({ ...form, email: e.target.value })}
            />
          </div>
          <div className="form mb-2">
            <input
              type="text"
              className="form-control"
              placeholder="Тема"
              onChange={(e) => setForm({ ...form, subject: e.target.value })}
            />
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="form textarea-footer">
            <textarea
              className="form-control"
              placeholder="Сообщение"
              onChange={(e) => setForm({ ...form, text: e.target.value })}
            />
          </div>
        </div>
        <div className="clr" />
        <button type="submit" className="btn btn-primary mt-2">
          Отправить
        </button>
      </form>
    )
  } else {
    return (
      <form onSubmit={handleSubmit}>
        <h5>Обратная связь</h5>
        <div className="col-12 mt-5">
          <div className="form mb-4">
            <input
              type="text"
              className="form-control"
              placeholder="Имя"
              onChange={(e) => setForm({ ...form, name: e.target.value })}
            />
          </div>
          <div className="form mb-4">
            <input
              type="text"
              className="form-control"
              placeholder="Компания"
              onChange={(e) => setForm({ ...form, subject: e.target.value })}
            />
          </div>
          <div className="form mb-4">
            <input
              type="email"
              className="form-control"
              placeholder="Email"
              onChange={(e) => setForm({ ...form, email: e.target.value })}
            />
          </div>
        </div>
        <div className="col-12 mb-4">
          <div className="form">
            <textarea
              className="form-control"
              placeholder="Сообщение"
              onChange={(e) => setForm({ ...form, text: e.target.value })}
            />
          </div>
        </div>
        <button type="submit" className="btn btn-primary mt-2">
          Отправить
        </button>
      </form>
    )
  }
}

export default Feedback
