import { createApi } from "@reduxjs/toolkit/query/react"

import {
  ChangePasswordRequest,
  ChangeSubscriptionTitleRequest,
  FeedbackRequest,
  IGetIdQuery,
  SaveUser,
  User,
  UserSubscription,
} from "../types/user"
import { IDefaultResponse } from "../types/api"

import { baseQuery } from "./baseQuery"

export const userApi = createApi({
  reducerPath: "user",
  baseQuery,
  tagTypes: ["USER", "SUBSCRIPTIONS"],
  endpoints: (build) => ({
    sendFeedback: build.mutation<void, FeedbackRequest>({
      query: (body) => ({
        url: "/sendcontact",
        body,
        method: "POST",
      }),
    }),
    getId: build.query<IGetIdQuery, void>({
      query: () => "/getuserid",
    }),
    getCurrentUser: build.query<IDefaultResponse<User>, number>({
      query: (id) => `/getuser/${id}`,
      providesTags: ["USER"],
    }),
    getSubscriptions: build.query<IDefaultResponse<UserSubscription[]>, number>(
      {
        query: (id) => `/getsubscriptions/${id}`,
        providesTags: ["SUBSCRIPTIONS"],
      },
    ),
    saveUser: build.mutation<IDefaultResponse<void>, SaveUser>({
      query: (body) => ({
        url: "/senduser",
        body,
        method: "POST",
      }),
      invalidatesTags: ["USER"],
    }),
    changePassword: build.mutation<
      IDefaultResponse<void>,
      ChangePasswordRequest
    >({
      query: (body) => ({
        url: "/sendnewpassword",
        body,
        method: "POST",
      }),
    }),
    subscribeToFilter: build.mutation<
      IDefaultResponse<void>,
      { id: number; url: string }
    >({
      query: (body) => ({
        url: "/sendsubscribe",
        body,
        method: "POST",
      }),
      invalidatesTags: ["SUBSCRIPTIONS"],
    }),
    changeSubscriptionTitle: build.mutation<
      IDefaultResponse<void>,
      ChangeSubscriptionTitleRequest
    >({
      query: (body) => ({
        url: "/sendsubscribename",
        body,
        method: "POST",
      }),
      invalidatesTags: ["SUBSCRIPTIONS"],
    }),
    deleteSubscription: build.mutation<IDefaultResponse<void>, number>({
      query: (id_subscriptions) => ({
        url: "/deletesubscribe",
        body: { id_subscriptions },
        method: "POST",
      }),
      invalidatesTags: ["SUBSCRIPTIONS"],
    }),
  }),
})
