import vk from "../resources/vk.svg"
import {
  ADDRESS_WITH_UNIVERSITY_NAME,
  EMAIL,
  EMAIL_LINK,
  // PHONE,
  // PHONE_LINK,
  SITE,
  TELEGRAM,
  VK,
} from "../consts/contacts"
import Feedback from "../components/Feedback"
import Layout from "../components/Layout"

const ContactsPage = () => {
  return (
    <Layout title="Контакты - Центр трансфера технологий">
      <div className="row">
        <h1 className="h1-st mb-4 mt-4">Контакты</h1>

        <div className="col-xs-12 col-sm-12 col-md-12 col-xxl-12">
          <section className="kontakty">
            <article className="mb-5">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-6 mt-4">
                  <h4>
                    Центр трансфера технологий
                    <br />
                    Пермского Политеха
                  </h4>
                  <div className="footadres">
                    {ADDRESS_WITH_UNIVERSITY_NAME}
                  </div>
                  <p className="foottel">
                    Сайт: <a href={`https://${SITE}`}>{SITE}</a>
                  </p>
                  {/*<p className="foottel">*/}
                  {/*  Телефон: <a href={PHONE_LINK}>{PHONE}</a>*/}
                  {/*</p>*/}
                  <p className="footmail">
                    E-mail: <a href={EMAIL_LINK}>{EMAIL}</a>
                  </p>
                  <div className="soc mt-5 mb-3">
                    <a
                      className="vk-logo-contacts"
                      key="1"
                      href={VK}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={vk} alt="" />
                    </a>
                    <a
                      key="2"
                      href={TELEGRAM}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa fa-telegram" aria-hidden="true" />
                    </a>
                  </div>
                </div>

                <div className="col-12 col-md-5 offset-md-1 col-lg-5 mt-4">
                  <Feedback />
                </div>
              </div>
            </article>
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default ContactsPage
