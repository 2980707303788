import { FC } from "react"
import { NavLink } from "react-router-dom"

interface NavItemProps {
  title: string
  link: string
  disabled?: boolean
}

const NavItem: FC<NavItemProps> = ({ title, link, disabled }) => {
  return (
    <li>
      <NavLink
        to={link}
        className={({ isActive }) =>
          [disabled && "disabled-link", isActive && "selected-link"]
            .filter(Boolean)
            .join(" ")
        }
      >
        {title}
      </NavLink>
    </li>
  )
}

export default NavItem
