const LoaderPage = () => {
  return (
    <div className="vh-100 position-relative">
      <div className="position-absolute top-50 start-50 translate-middle">
        <div
          className="spinner-border"
          style={{ color: "#002fe7" }}
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  )
}

export default LoaderPage
