import { ChangeEvent, FC } from "react"

import Tooltip from "../../Tooltip"

interface GroupSelectProps {
  id: string
  label: string
  name: string
  required?: boolean
  isBinary?: boolean
  options?: any[]
  optionIdProp?: string
  optionValueProp?: string
  selectClassName?: string
  value?: string
  tooltip?: string
  onChange?: (e: ChangeEvent<HTMLSelectElement>) => void
}

const GroupSelect: FC<GroupSelectProps> = ({
  required,
  label,
  id,
  isBinary,
  options,
  optionValueProp,
  optionIdProp,
  selectClassName,
  value,
  onChange,
  name,
  tooltip,
}) => {
  return (
    <div className="my-3">
      <p className="d-flex align-items-center justify-content-between">
        <label htmlFor={id} className="mb-0">
          {required && <span className="required">*</span>}
          {label}
        </label>
        {tooltip && <Tooltip tooltip={tooltip} />}
      </p>
      <select
        className={selectClassName ?? "w-100"}
        id={id}
        name={name}
        value={value}
        onChange={onChange}
      >
        {isBinary ? (
          <>
            <option value="1">Да</option>
            <option value="0">Нет</option>
          </>
        ) : (
          optionIdProp &&
          optionValueProp &&
          options?.map((option) => (
            <option key={option[optionIdProp]} value={option[optionIdProp]}>
              {option[optionValueProp]}
            </option>
          ))
        )}
      </select>
    </div>
  )
}

export default GroupSelect
