import { FC, FormEvent, useEffect, useRef } from "react"
import { toast } from "react-toastify"

import { useActionCreators, useTypedSelector } from "../../hooks/redux"
import { userApi } from "../../services/userService"

interface ChangeSubscriptionTitleModalProps {
  subscriptionId: number
  initialTitle: string
}

const ChangeSubscriptionTitleModal: FC<ChangeSubscriptionTitleModalProps> = ({
  subscriptionId,
  initialTitle,
}) => {
  const actions = useActionCreators()

  const [changeSubTitle] = userApi.useChangeSubscriptionTitleMutation()

  const isVisible = useTypedSelector(
    (state) => state.modalReducer.isChangeSubscriptionTitleModalVisible,
  )

  const titleRef = useRef<HTMLInputElement>(null)

  const onClose = () => {
    actions.setChangeSubscriptionTitleModalVisible(false)
  }

  const onSubmit = (e: FormEvent) => {
    e.preventDefault()

    if (!titleRef.current?.value) {
      return toast.warning("Введите название подписки")
    }

    changeSubTitle({
      id_subscriptions: subscriptionId,
      subscription_name: titleRef.current.value,
    })

    actions.setChangeSubscriptionTitleModalVisible(false)
  }

  useEffect(() => {
    if (!titleRef?.current?.value) return

    titleRef.current.value = initialTitle
  }, [initialTitle])

  return (
    <div id="modal2" style={{ display: isVisible ? "block" : "" }}>
      <div className="overlay-modal" onClick={onClose}></div>
      <div className="aside-b subscribe">
        <form onSubmit={onSubmit}>
          <h4>Изменение названия подписки</h4>
          <div className="form mb-2">
            <input
              type="text"
              className="form-control"
              placeholder="Название"
              defaultValue={initialTitle}
              ref={titleRef}
            />
          </div>
          <button type="submit" className="btn btn-primary my-2">
            Сохранить
          </button>
        </form>
      </div>
    </div>
  )
}

export default ChangeSubscriptionTitleModal
