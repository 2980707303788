import { memo, useRef } from "react"

import { supportApi } from "../../services/supportService"
import { useActionCreators, useTypedSelector } from "../../hooks/redux"
import FilterGroup from "../SidebarFilter/FilterGroup"
import SumRange from "../SidebarFilter/SumRange"

const SupportFilter = () => {
  const actions = useActionCreators()

  const { data: supportForms } = supportApi.useGetSupportFormsQuery()
  const { data: supportDirections } = supportApi.useGetSupportDirectionsQuery()
  const { data: supportMembers } = supportApi.useGetSupportMembersQuery()

  const filter = useTypedSelector((state) => state.filterReducer.supports)
  const user = useTypedSelector((state) => state.userReducer.user)

  const membersLengthRef = useRef(filter.members.length)
  const formsLengthRef = useRef(filter.forms.length)

  const handleSubscribe = () => {
    if (!user) {
      actions.setAuthModalVisible(true)
    } else {
      actions.setSubscribeModalVisible(true)
    }
  }

  return (
    <div className="col-xs-12 col-xl-3 col-xxl-2 col-sm-12 col-lg-3 col-md-4">
      <aside>
        <div className="filtr">
          <form className="mb-5">
            {supportDirections && (
              <FilterGroup
                id="collapse1"
                initIsOpen
                title="Направление"
                items={supportDirections}
                name="directions"
                reducerName="supports"
              />
            )}
            {supportMembers && (
              <FilterGroup
                id="collapse2"
                initIsOpen={membersLengthRef.current > 0}
                title="Участники"
                items={supportMembers}
                name="members"
                reducerName="supports"
              />
            )}
            {supportForms && (
              <FilterGroup
                id="collapse3"
                initIsOpen={formsLengthRef.current > 0}
                title="Формы поддержки"
                items={supportForms}
                name="forms"
                reducerName="supports"
              />
            )}
            {(filter.totalMinAmount !== 0 || filter.totalMaxAmount !== 0) && (
              <>
                <h5 className="mt-4">Сумма</h5>
                <SumRange />
              </>
            )}
            <button
              className="btn btn-primary mt-3"
              type="button"
              onClick={handleSubscribe}
            >
              Подписка на фильтр
            </button>
          </form>
        </div>
      </aside>
    </div>
  )
}

export default memo(SupportFilter)
