import { FC, memo, useEffect, useRef, useState } from "react"
import { toast } from "react-toastify"

import { authApi } from "../../services/authService"
import Spinner from "../Spinner"
import { useActionCreators } from "../../hooks/redux"
import Arrow from "../Icons/Arrow"

import PasswordReset from "./PasswordReset"

interface LoginFormProps {
  email: string
  onBack: () => void
}

const LoginForm: FC<LoginFormProps> = ({ email, onBack }) => {
  const actions = useActionCreators()

  const [sendAuth, { data: authResponse, isLoading }] =
    authApi.useSendAuthMutation()

  const [password, setPassword] = useState("")
  const [isResettingPassword, setIsResettingPassword] = useState(false)

  const inputRef = useRef<HTMLInputElement | null>(null)

  const onLogin = async () => {
    if (!password.length) {
      toast.warning("Введите пароль")

      return
    }

    sendAuth({ email, password })
  }

  useEffect(() => {
    inputRef.current && inputRef.current?.focus()
  }, [])

  useEffect(() => {
    if (
      authResponse?.status === "error" &&
      authResponse.error_msg &&
      authResponse.error_msg[0] === "incorrect_password"
    ) {
      toast.error("Неправильный пароль!")
    }

    if (authResponse?.status === "successful") {
      authResponse?.data && actions.setUser(authResponse.data)
      actions.setAuthModalVisible(false)
    }
  }, [actions, authResponse])

  if (isResettingPassword) {
    return (
      <PasswordReset
        email={email}
        onBack={() => setIsResettingPassword(false)}
      />
    )
  }

  return (
    <form>
      <h4>Вход</h4>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <button className="btn-back" onClick={onBack}>
            <Arrow />
          </button>
          <div className="form mb-2">
            <input
              type="email"
              className="form-control"
              placeholder="Email"
              disabled
              value={email}
            />
          </div>
          <div className="form mb-2">
            <input
              type="password"
              className="form-control"
              placeholder="Пароль"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              ref={inputRef}
            />
          </div>
          <div className="clr"></div>
          <button
            type="button"
            onClick={onLogin}
            disabled={!password.length}
            className="btn btn-primary my-2"
          >
            Войти
          </button>
          <div className="zabyl">
            <a href="#" onClick={() => setIsResettingPassword(true)}>
              Забыли пароль?
            </a>
          </div>
        </>
      )}
    </form>
  )
}

export default memo(LoginForm)
