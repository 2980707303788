import { FC, useMemo } from "react"

import { useActionCreators, useTypedSelector } from "../../hooks/redux"
import {
  InnovationFilterFormKeys,
  InstituteFilterFormKeys,
  SupportFilterFormKeys,
} from "../../types/filterSlice"
import {
  instanceOfInnovationFilter,
  instanceOfInnovationFilterArray,
  instanceOfInstituteFilter,
  instanceOfInstituteFilterArray,
  instanceOfSupportFilter,
  instanceOfSupportFilterArray,
} from "../../utils/typeguard"

interface FilterCheckProps {
  title: string
  id: number
  name:
    | SupportFilterFormKeys
    | InstituteFilterFormKeys
    | InnovationFilterFormKeys
  type: "institution" | "supports" | "innovations"
}

const FilterCheck: FC<FilterCheckProps> = ({ id, title, name, type }) => {
  const actions = useActionCreators()
  const filter = useTypedSelector((state) => state.filterReducer)

  const handleCheck = () => {
    if (type === "supports" && instanceOfSupportFilter(name)) {
      actions.setSupportFilter({ id: name, value: id })

      return
    }

    if (type === "institution" && instanceOfInstituteFilter(name)) {
      actions.setInstitutesFilter({ id: name, value: id })

      return
    }

    if (type === "innovations" && instanceOfInnovationFilter(name)) {
      actions.setInnovationFilter({ id: name, value: id })

      return
    }
  }

  const isChecked = useMemo(() => {
    if (type === "supports" && instanceOfSupportFilterArray(name)) {
      return filter[type][name].includes(id)
    }

    if (type === "institution" && instanceOfInstituteFilterArray(name)) {
      return filter[type][name].includes(id)
    }

    if (type === "innovations" && instanceOfInnovationFilterArray(name)) {
      return filter[type][name].includes(id)
    }

    return false
  }, [filter, id, name, type])

  return (
    <div className="form-check">
      <input
        className="form-check-input"
        type="checkbox"
        id={`${name}-${id}`}
        value={title}
        onChange={handleCheck}
        checked={isChecked}
      />
      <label className="form-check-label" htmlFor={`${name}-${id}`}>
        {title}
      </label>
    </div>
  )
}

export default FilterCheck
