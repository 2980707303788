import { createApi } from "@reduxjs/toolkit/query/react"

import { IDefaultQuery, IDefaultResponse } from "../types/api"
import {
  CreateInnovation,
  Innovation,
  InnovationAdditionalField,
  InnovationDefaultField,
  InnovationDemand,
  InnovationDirection,
  InnovationFinanceSource,
  InnovationIndustry,
  InnovationMarket,
  InnovationSearch,
  InnovationShort,
  InnovationStage,
  InnovationTechnology,
  InnovationTRL,
} from "../types/innovations"
import { UniversalFilterType } from "../types/common"

import { baseQuery } from "./baseQuery"

export const innovationApi = createApi({
  reducerPath: "innovation",
  baseQuery,
  endpoints: (build) => ({
    getInnovations: build.query<
      IDefaultResponse<InnovationShort[]>,
      IDefaultQuery<InnovationSearch>
    >({
      query: ({ page, search }) => ({
        url: `/getinnovations/${page}`,
        body: search ? { search } : {},
        method: "POST",
      }),
    }),
    getInnovation: build.query<IDefaultResponse<Innovation>, number>({
      query: (id) => `/getinnovation/${id}`,
    }),
    createInnovation: build.mutation<
      IDefaultResponse<void> & { id_innovations: number },
      CreateInnovation
    >({
      query: (body) => ({
        url: "/sendinnovation",
        body,
        method: "POST",
      }),
    }),
    getIndustries: build.query<IDefaultResponse<InnovationIndustry[]>, void>({
      query: () => "/getinnovationindustries",
    }),
    getSearchIndustries: build.query<UniversalFilterType[], void>({
      query: () => "/getsearchinnovationindustries",
      transformResponse(response: IDefaultResponse<InnovationIndustry[]>) {
        return (
          response.data?.map((industry) => ({
            id: industry.id_industries,
            title: industry.industry_name,
          })) ?? []
        )
      },
    }),
    getMarkets: build.query<IDefaultResponse<InnovationMarket[]>, void>({
      query: () => "/getinnovationmarkets",
    }),
    getSearchMarkets: build.query<UniversalFilterType[], void>({
      query: () => "/getsearchinnovationmarkets",
      transformResponse(response: IDefaultResponse<InnovationMarket[]>) {
        return (
          response.data?.map((market) => ({
            id: market.id_innovation_markets,
            title: market.market_name,
          })) ?? []
        )
      },
    }),
    getStages: build.query<IDefaultResponse<InnovationStage[]>, void>({
      query: () => "/getinnovationstages",
    }),
    getDefaultFields: build.query<Record<string, InnovationDefaultField>, void>(
      {
        query: () => "/getdefaultfields",
        transformResponse: (
          response: IDefaultResponse<InnovationDefaultField[]>,
        ) => {
          const returnObj: Record<string, InnovationDefaultField> = {}
          const data = response.data

          data?.forEach((field) => (returnObj[field.field_name] = field))

          return returnObj
        },
      },
    ),
    getAdditionalFields: build.query<
      IDefaultResponse<InnovationAdditionalField[]>,
      void
    >({
      query: () => "/getadditionalfields",
    }),
    getTRL: build.query<IDefaultResponse<InnovationTRL[]>, void>({
      query: () => "/getinnovationtrls",
    }),
    getDirections: build.query<IDefaultResponse<InnovationDirection[]>, void>({
      query: () => "/getinnovationdirections",
    }),
    getTechnologies: build.query<
      IDefaultResponse<InnovationTechnology[]>,
      void
    >({
      query: () => "/getinnovationtechnologies",
    }),
    getFinanceSources: build.query<
      IDefaultResponse<InnovationFinanceSource[]>,
      void
    >({
      query: () => "/getinnovationfinancesources",
    }),
    getDemands: build.query<IDefaultResponse<InnovationDemand[]>, void>({
      query: () => "/getinnovationdemands",
    }),
  }),
})
