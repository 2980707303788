import { FormEvent, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

import Layout from "../components/Layout"
import { innovationApi } from "../services/innovationService"
import ShortInfo from "../components/InnovationForm/ShortInfo"
import Team from "../components/InnovationForm/Team"
import DetailedInfo from "../components/InnovationForm/DetailedInfo"
import Demand from "../components/InnovationForm/Demand"
import Files from "../components/InnovationForm/Files"
import { validateCreateInnovationForm } from "../utils/validateCreateInnovationForm"
import AdditionalInfo from "../components/InnovationForm/AdditionalInfo"
import { getInnovationFromForm } from "../utils/getInnovationFromForm"
import { MAIN_PAGE_ROUTE } from "../consts/routes"

import LoaderPage from "./LoaderPage"

const wrapperClass = "col-12 col-md-6 col-xl-6"

const InnovationEditorPage = () => {
  const navigate = useNavigate()

  const { data: defaultFields } = innovationApi.useGetDefaultFieldsQuery()
  const { data: additionalFields } = innovationApi.useGetAdditionalFieldsQuery()
  const [createInnovation, { data: createdInnovation }] =
    innovationApi.useCreateInnovationMutation()

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()

    if (!defaultFields) return

    if (!additionalFields?.data) return

    const form = e.target as HTMLFormElement
    const formData = new FormData(form)
    const formValues = Object.fromEntries(formData.entries())

    const isFormValid = validateCreateInnovationForm(
      form,
      formValues,
      defaultFields,
      additionalFields.data,
    )

    if (!isFormValid) return

    const innovation = await getInnovationFromForm(
      formValues,
      defaultFields,
      additionalFields.data,
    )

    createInnovation(innovation)
  }

  useEffect(() => {
    if (!createdInnovation) return

    if (createdInnovation.status === "error") {
      toast.error("Произошла неизвестная ошибка. Повторите попытку позже")
    } else {
      toast.success(
        `Ваша заявка принята. Ее уникальный номер: ${createdInnovation.id_innovations}`,
      )
      navigate(MAIN_PAGE_ROUTE)
    }
  }, [createdInnovation, navigate])

  if (!defaultFields) {
    return <LoaderPage />
  }

  return (
    <Layout title="Добавление разработки - Центр трансфера технологий">
      <form onSubmit={onSubmit} className={wrapperClass + " lkab"} noValidate>
        <div className="edit row flex-column">
          <h1 className="h1-st mb-4 mt-4">Добавление разработки</h1>

          <ShortInfo />

          <Team />

          <DetailedInfo />

          <Demand />

          <AdditionalInfo />

          <Files />

          <div>
            <button className="btn btn-primary mb-5 mt-3 w-100" type="submit">
              Подать заявку
            </button>
          </div>
        </div>
      </form>
    </Layout>
  )
}

export default InnovationEditorPage
