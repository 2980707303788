import { useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { toast } from "react-toastify"

import { authApi } from "../services/authService"
import { MAIN_PAGE_ROUTE } from "../consts/routes"
import { useActionCreators } from "../hooks/redux"

import LoaderPage from "./LoaderPage"

const ConfirmEmailPage = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { setUser } = useActionCreators()

  const [confirmEmail, { data: confirmEmailResponse }] =
    authApi.useConfirmEmailMutation()

  useEffect(() => {
    const id = searchParams.get("id")
    const hash = searchParams.get("hash")

    if (id && +id && hash) {
      confirmEmail({ id: +id, hash })
    } else {
      navigate(MAIN_PAGE_ROUTE)
      toast.error("Ошибка при подтверждении почты")
    }
  }, [confirmEmail, navigate, searchParams])

  useEffect(() => {
    if (!confirmEmailResponse) return

    if (confirmEmailResponse.status === "error") {
      navigate(MAIN_PAGE_ROUTE)
      toast.error("Ошибка при подтверждении почты")

      return
    }

    if (confirmEmailResponse.status === "successful") {
      confirmEmailResponse.data && setUser(confirmEmailResponse.data)

      navigate(MAIN_PAGE_ROUTE)
      toast.success("Вы успешно подтвердили почту")

      return
    }
  }, [confirmEmailResponse, navigate, setUser])

  return <LoaderPage />
}

export default ConfirmEmailPage
