import { FC } from "react"
import { Helmet, HelmetProvider } from "react-helmet-async"

interface HeadMetaComponentProps {
  title: string
  description?: string
  keywords?: string
}

const HeadMetaComponent: FC<HeadMetaComponentProps> = ({
  title,
  description,
  keywords,
}) => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>{title}</title>
        {description && <meta name="description" content={description} />}
        {keywords && <meta name="keywords" content={keywords} />}
      </Helmet>
    </HelmetProvider>
  )
}

export default HeadMetaComponent
