import { combineReducers, configureStore } from "@reduxjs/toolkit"

import { supportApi } from "../services/supportService"
import { instituteApi } from "../services/instituteService"
import { newsApi } from "../services/newsService"
import { userApi } from "../services/userService"
import { staticApi } from "../services/staticService"
import { innovationApi } from "../services/innovationService"
import { authApi } from "../services/authService"
import { miscApi } from "../services/miscService"

import { filterReducer } from "./reducers/filterSlice"
import { newsReducer } from "./reducers/newsSlice"
import { modalReducer } from "./reducers/modalSlice"
import { userReducer } from "./reducers/userSlice"

const rootReducer = combineReducers({
  filterReducer,
  newsReducer,
  modalReducer,
  userReducer,
  [supportApi.reducerPath]: supportApi.reducer,
  [instituteApi.reducerPath]: instituteApi.reducer,
  [newsApi.reducerPath]: newsApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [staticApi.reducerPath]: staticApi.reducer,
  [innovationApi.reducerPath]: innovationApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [miscApi.reducerPath]: miscApi.reducer,
})

export const initStore = () =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(
        supportApi.middleware,
        instituteApi.middleware,
        newsApi.middleware,
        userApi.middleware,
        staticApi.middleware,
        innovationApi.middleware,
        authApi.middleware,
        miscApi.middleware,
      ),
  })

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof initStore>
export type AppDispatch = AppStore["dispatch"]
