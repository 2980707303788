import { ChangeEvent, FC, useState } from "react"

import { useActionCreators, useTypedSelector } from "../../hooks/redux"

interface TwoSideRangeProps {
  title: string
  initIsOpen: boolean
}

const TwoSideRange: FC<TwoSideRangeProps> = ({ title, initIsOpen }) => {
  const actions = useActionCreators()

  const range = useTypedSelector((state) => state.filterReducer.innovations.trl)

  const [isOpened, setIsOpened] = useState(true)

  const handleMinChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.valueAsNumber

    actions.setInnovationFilter({ id: "trl", value: [value, range[1] ?? 9] })
  }

  const handleMaxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.valueAsNumber

    actions.setInnovationFilter({ id: "trl", value: [range[0] ?? 1, value] })
  }

  return (
    <>
      <h5 className="mt-4">
        <a
          data-bs-toggle="collapse"
          href="#collapse5"
          role="button"
          aria-expanded="false"
          aria-controls="collapse5"
          onClick={() => setIsOpened((prev) => !prev)}
        >
          {title}
          <i
            aria-hidden="true"
            className={isOpened ? "fa fa-caret-up" : "fa fa-caret-down"}
          />
        </a>
      </h5>
      <div id="collapse5" className={`collapse${initIsOpen ? " show" : ""}`}>
        <div className="polzunok">
          <input
            type="range"
            min="1"
            max="9"
            value={range[0] ?? 1}
            name="range"
            step="1"
            onChange={handleMinChange}
          />
          <input
            className="input-lay"
            type="range"
            min="1"
            max="9"
            value={range[1] ?? 9}
            name="range"
            step="1"
            onChange={handleMaxChange}
          />
          <div className="znach">
            <b>1</b>
            <b>2</b>
            <b>3</b>
            <b>4</b>
            <b>5</b>
            <b>6</b>
            <b>7</b>
            <b>8</b>
            <b>9</b>
          </div>
        </div>
      </div>
    </>
  )
}

export default TwoSideRange
