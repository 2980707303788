import { useCallback, useState } from "react"

import Header from "./Header"
import NavItems from "./NavItems"

const HeaderMenu = () => {
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false)

  const onBurgerClick = useCallback(
    () => setMobileMenuIsOpen((prev) => !prev),
    [],
  )

  return (
    <>
      <Header onBurgerClick={onBurgerClick} />
      <nav className="d-none d-md-block">
        <NavItems />
      </nav>
      <div
        className="navmobile mb-4"
        id="navmobile"
        style={{
          display: mobileMenuIsOpen ? "block" : "none",
        }}
      >
        <div className="row">
          <NavItems />
        </div>
      </div>
    </>
  )
}

export default HeaderMenu
