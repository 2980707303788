import { FC, PropsWithChildren } from "react"

interface TextFormProps {
  title: string
}

const TextForm: FC<PropsWithChildren<TextFormProps>> = ({
  title,
  children,
}) => {
  return (
    <div>
      <h4>{title}</h4>
      <div className="form-text mt-2 mb-0">{children}</div>
    </div>
  )
}

export default TextForm
