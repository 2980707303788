import { FC } from "react"
import { Link } from "react-router-dom"

import { HOST } from "../../consts/hosts"
import defaultImage from "../../resources/default_image.png"
import { INSTITUTES_PAGE_ROUTE } from "../../consts/routes"
import { Institute } from "../../types/institutes"

interface InstituteCardProps {
  institute: Institute
}

const INSTITUTE_PAGE = INSTITUTES_PAGE_ROUTE.slice(0, -1)

const InstituteCard: FC<InstituteCardProps> = ({ institute }) => {
  return (
    <article className="col-6 mb-5">
      <div className="article">
        <Link
          to={`${INSTITUTE_PAGE}/${institute.id_institutes}`}
          className="stretched-link"
        >
          <div className="inst-img d-flex">
            <img
              className="d-block m-auto mh-100 mw-100"
              src={institute.logo ? `${HOST}${institute.logo}` : defaultImage}
              alt={institute.institute_name}
            />
          </div>
          <h2 className="mt-4 mb-4">{institute.institute_name}</h2>

          {institute.institution_forms &&
            institute.institution_forms.map((form) => (
              <div
                className="float-start a-univ"
                key={form.id_institution_forms}
              >
                {form.institution_form_name}
              </div>
            ))}

          <div className="clr" />
          {institute.regions &&
            institute.regions.map((region) => (
              <div
                className="float-start a-gorod"
                key={region.id_institution_regions}
              >
                {region.region_name}
              </div>
            ))}
          {institute.count_support ? (
            <div className="float-end a-proj mt-2">
              <b>{institute.count_support}</b>
              <u>
                количество
                <br />
                мер поддержки
              </u>
            </div>
          ) : (
            <div />
          )}
          <div className="clr" />
        </Link>
      </div>
      <div className="float-end homebtn">
        <Link to={`${INSTITUTE_PAGE}/${institute.id_institutes}`}>
          Подробнее
        </Link>
      </div>
    </article>
  )
}

export default InstituteCard
