import { FC } from "react"

import { InnovationVacancy } from "../../types/innovations"

const VacancyCard: FC<{ vacancy: InnovationVacancy }> = ({ vacancy }) => {
  return (
    <div className="col-6 col-md-6 col-lg-4">
      <div>
        <table>
          <tbody>
            <tr>
              <td className="vak-h">{vacancy.vacancy_name}</td>
            </tr>
            <tr>
              <td className="vak-o">{vacancy.description}</td>
            </tr>
            <tr>
              <td className="vak-kol">
                <b>{vacancy.amount}</b>
                {" " +
                  ([2, 3, 4].includes(vacancy.amount % 10)
                    ? "человека"
                    : "человек")}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default VacancyCard
