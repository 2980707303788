import { FC } from "react"
import { InView } from "react-intersection-observer"

import {
  InnovationAdditionalFieldSet,
  InnovationData,
} from "../../types/innovations"

import InfoField from "./InfoField"

interface AdditionalInfoProps {
  additionalData: InnovationData[]
  onPrivateFieldIntersect: (
    inView: boolean,
    entry: IntersectionObserverEntry,
  ) => void
}

const AdditionalInfo: FC<AdditionalInfoProps> = ({
  additionalData,
  onPrivateFieldIntersect,
}) => {
  return (
    <>
      {additionalData.map((field) => {
        const fieldType = field.id_field_types
        const fieldData = field.data

        if (fieldType === 1) {
          return (
            fieldData && (
              <InfoField title={field.label} key={field.id_innovation_fields}>
                <InView
                  as="p"
                  onChange={onPrivateFieldIntersect}
                  id={field.field_name}
                >
                  {typeof fieldData === "string" && fieldData}
                </InView>
              </InfoField>
            )
          )
        } else {
          const data = fieldData as InnovationAdditionalFieldSet[]

          return (
            data.length > 0 && (
              <InfoField title={field.label} key={field.id_innovation_fields}>
                <InView
                  as="p"
                  onChange={onPrivateFieldIntersect}
                  id={field.field_name}
                >
                  {data.map((d) => d.set_value).join(", ")}
                </InView>
              </InfoField>
            )
          )
        }
      })}
    </>
  )
}

export default AdditionalInfo
