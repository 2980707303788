import { FC } from "react"
import { InView } from "react-intersection-observer"

import { Innovation } from "../../types/innovations"
import ArrowImg from "../../resources/strelka.png"

interface SignificantDatesProps {
  innovation: Innovation
  onPrivateFieldIntersect: (
    inView: boolean,
    entry: IntersectionObserverEntry,
  ) => void
}

const SignificantDates: FC<SignificantDatesProps> = ({
  innovation,
  onPrivateFieldIntersect,
}) => {
  return (
    <div className="time-vitr mb-5">
      {innovation.start_date_dd && (
        <table>
          <tbody>
            <InView as="tr" onChange={onPrivateFieldIntersect} id="start_date">
              <td className="bl-time-1">
                Дата
                <br />
                начала проекта
              </td>
              <td className="bl-time-2">{innovation.start_date_dd}</td>
            </InView>
          </tbody>
        </table>
      )}
      {innovation.start_date_dd && innovation.first_sale_date_dd && (
        <table>
          <tbody>
            <tr>
              <td>
                <img src={ArrowImg} alt="" />
              </td>
            </tr>
          </tbody>
        </table>
      )}
      {innovation.first_sale_date_dd && (
        <table>
          <tbody>
            <InView
              as="tr"
              onChange={onPrivateFieldIntersect}
              id="first_sale_date"
            >
              <td className="bl-time-1">
                Планируемая дата
                <br />
                выхода на продажи
              </td>
              <td className="bl-time-2">{innovation.first_sale_date_dd}</td>
            </InView>
          </tbody>
        </table>
      )}
    </div>
  )
}

export default SignificantDates
