import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import Layout from "../components/Layout"
import { newsApi } from "../services/newsService"
import NewsCard from "../components/Cards/NewsCard"
import { useActionCreators, useTypedSelector } from "../hooks/redux"
import Paginate from "../components/Paginate"
import { getQueryString } from "../utils/getQueryString"
import { getObjectFromQuery } from "../utils/getObjectFromQuery"
import { NEWS_PAGE_ROUTE } from "../consts/routes"

const NewsPage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const actions = useActionCreators()

  const [getNews, { data: news }] = newsApi.useLazyGetNewsQuery()
  const { data: newsCategories } = newsApi.useGetNewsCategoriesQuery()

  const currentTag = useTypedSelector((state) => state.newsReducer.currentTag)

  const [page, setPage] = useState(1)
  const pageSize = useRef(9)

  const pageCount = useMemo(
    () => (news ? Math.ceil(news.count / pageSize.current) : 0),
    [news],
  )

  const query = useMemo(
    () => getQueryString({ page, page_size: pageSize.current }),
    [page],
  )

  const onNewsTagClick = (tag: string | null) => {
    actions.switchTag(tag)
  }

  const onPageChange = useCallback(
    (e: { selected: number }) => setPage(e.selected + 1),
    [],
  )

  useEffect(() => {
    const queryObject = getObjectFromQuery(location.search)

    if (queryObject.page) {
      setPage(queryObject.page)
    }

    if (queryObject.page_size) {
      pageSize.current = queryObject.page_size
    }

    if (queryObject.tag) {
      actions.switchTag(queryObject.tag)
    }
  }, [actions, location.search])

  useEffect(() => {
    getNews({
      page,
      search: {
        maxelem: pageSize.current,
        news_categories: currentTag,
      },
    })
  }, [currentTag, getNews, page])

  useEffect(() => {
    navigate({
      search: query,
    })
  }, [navigate, query])

  useEffect(() => {
    if (currentTag === null) {
      navigate(NEWS_PAGE_ROUTE)
    } else {
      navigate(NEWS_PAGE_ROUTE + `/tag/${currentTag}`)
    }
  }, [currentTag, navigate])

  return (
    <Layout title="Новости - Центр трансфера технологий">
      <div className="row">
        <h1 className="h1-st mb-4 mt-4">Новости</h1>
        <div className="col-12">
          <section className="mb-4 news">
            <ul className="tags mb-5 ps-0">
              <li onClick={() => actions.switchTag(null)}>Все новости</li>
              {newsCategories?.data?.length &&
                newsCategories.data
                  .filter((n) => n.alt_name !== null)
                  .map((category) => (
                    <li
                      key={category.id_news_categories}
                      onClick={() => actions.switchTag(category.alt_name)}
                    >
                      {category.title}
                    </li>
                  ))}
            </ul>

            <div className="row inst">
              {news?.list.map((news) => (
                <NewsCard
                  key={news.id_news}
                  handleCategoryClick={onNewsTagClick}
                  news={news}
                />
              ))}

              <div className="clr" />

              <Paginate
                initPage={page - 1}
                pageCount={pageCount || 1}
                onPageChange={onPageChange}
              />
            </div>
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default NewsPage
