import { useState, useMemo, useCallback, useEffect } from "react"
import { toast } from "react-toastify"

import { useActionCreators, useTypedSelector } from "../../hooks/redux"
import { isEmail } from "../../utils/isEmail"
import EmailForm from "../ModalForms/EmailForm"
import LoginForm from "../ModalForms/LoginForm"
import RegistrationForm from "../ModalForms/RegistrationForm"
import TextForm from "../ModalForms/TextForm"
import { HELP_EMAIL } from "../../consts/contacts"
import { authApi } from "../../services/authService"

const USER_EXISTS = "exist"
const USER_BANNED = "archive"
const USER_DOES_NOT_EXIST = "no_user"

const AuthModal = () => {
  const { setAuthModalVisible } = useActionCreators()

  const [checkEmail, { data: checkEmailResponse, isLoading }] =
    authApi.useCheckEmailMutation()

  const isAuthModalVisible = useTypedSelector(
    (state) => state.modalReducer.isAuthModalVisible,
  )

  const [email, setEmail] = useState("")
  const [status, setStatus] = useState("email")

  const isEmailOk = useMemo(() => isEmail(email), [email])

  const onClose = () => setAuthModalVisible(false)

  const sendEmail = useCallback(async () => {
    if (!isEmailOk) {
      toast.warning("Введите валидный email")

      return
    }

    checkEmail({ email })
  }, [checkEmail, email, isEmailOk])

  useEffect(() => {
    if (!checkEmailResponse) return

    if (checkEmailResponse.status === "error") {
      if (checkEmailResponse.error_msg) {
        setStatus(checkEmailResponse.error_msg[0])
      }

      return
    }

    if (checkEmailResponse.status === "successful") {
      setStatus(USER_EXISTS)
    }
  }, [checkEmailResponse])

  return (
    <div id="modal" style={{ display: isAuthModalVisible ? "block" : "" }}>
      <div className="overlay-modal" onClick={onClose}></div>
      <div className="aside-b subscribe">
        {status === "email" && (
          <EmailForm
            email={email}
            setEmail={setEmail}
            isBtnDisabled={!isEmailOk}
            onBtnClick={sendEmail}
            isLoading={isLoading}
          />
        )}
        {status === USER_EXISTS && (
          <LoginForm email={email} onBack={() => setStatus("email")} />
        )}
        {status === USER_BANNED && (
          <TextForm title="Вход запрещен">
            Ваш аккаунт был заблокирован, свяжитесь с нами по почте{" "}
            <a href={`mailto:${HELP_EMAIL}`}>{HELP_EMAIL}</a>
          </TextForm>
        )}
        {status === USER_DOES_NOT_EXIST && (
          <RegistrationForm email={email} onBack={() => setStatus("email")} />
        )}
      </div>
    </div>
  )
}

export default AuthModal
