import { InnovationAdditionalFieldSet } from "../types/innovations"

export function getFormRadioValueByName(
  name: string,
  sets: InnovationAdditionalFieldSet[],
) {
  const inputs = document.querySelectorAll(`[name=${name}]`)

  let value: { id_field_sets: number; set_value: string }[] = []

  inputs.forEach((input) => {
    const radio = input as HTMLInputElement

    const id = radio.dataset.id

    if (!id) return

    if (radio.checked) {
      const set = sets.find((set) => set.id_field_sets === +id)

      if (set) {
        value = [set]
      }
    }
  })

  return value
}
