import { ChangeEvent, FC, FocusEvent } from "react"

import { InnovationDefaultField } from "../../../types/innovations"
import Tooltip from "../../Tooltip"

interface TextAreaInputProps {
  field: Pick<InnovationDefaultField, "label" | "required" | "field_name"> & {
    tooltip?: string
  }
  name: string
}

const TextAreaInput: FC<TextAreaInputProps> = ({ field, name }) => {
  const id = "innovation_" + field.field_name

  const onBlurIfRequired = (e: FocusEvent<HTMLTextAreaElement>) => {
    if (!field.required) return

    e.target.value === "" && e.target.classList.add("field-required")
  }

  const onChangeIfRequired = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (!field.required) return

    e.target.classList.remove("field-required")
  }

  return (
    <div className="my-3">
      <p className="d-flex align-items-center justify-content-between">
        {field.label && (
          <label htmlFor={id} className="mb-0">
            {field.required && <span className="required">*</span>}
            {field.label}
          </label>
        )}
        {field.tooltip && <Tooltip tooltip={field.tooltip} />}
      </p>
      <textarea
        className="form-control w-100"
        id={id}
        name={name}
        rows={10}
        maxLength={1000}
        onBlur={onBlurIfRequired}
        onChange={onChangeIfRequired}
      ></textarea>
    </div>
  )
}

export default TextAreaInput
