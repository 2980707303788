export function getObjectFromQuery(
  query: string,
  arrayKeys?: string[],
  booleanKeys?: string[],
) {
  if (!query) return { page: 1, page_size: 8 }

  const queryMap = new Map()

  query
    .slice(1)
    .split("&")
    .forEach((q) => {
      const [key, value] = q.split("=")

      if (arrayKeys?.includes(key)) {
        const arr = decodeURIComponent(value)
          .split(",")
          .map((v) => +v)

        if (arr.filter((v) => isNaN(v) || v === 0).length) {
          return
        }

        queryMap.set(key, arr)

        return
      }

      if (booleanKeys?.includes(key)) {
        queryMap.set(key, value === "" ? null : value === "true")

        return
      }

      queryMap.set(
        key,
        value && !isNaN(+value) ? +value : decodeURIComponent(value),
      )
    })

  return Object.fromEntries(queryMap)
}
