const valueIsHTMLElement = (
  value: HTMLElement | RadioNodeList,
): value is HTMLElement => {
  return "className" in value
}

export function checkRequiredField<T extends HTMLElement | RadioNodeList>(
  formValues: Record<string, FormDataEntryValue>,
  elements: HTMLFormControlsCollection,
  elementName: string,
  options?: {
    customStringCheck?: (str: string) => boolean
    isCheckboxMultiple?: boolean
  },
) {
  const input = elements.namedItem(elementName) as T

  if (!input) throw Error("Element is missing")

  const value = formValues[elementName]

  let isValueInvalid

  if (options?.isCheckboxMultiple && !valueIsHTMLElement(input)) {
    const list = Array.from(input)

    const isAtLeastOneChecked =
      list
        .map((checkbox) => (checkbox as HTMLInputElement).checked)
        .filter(Boolean).length > 0

    if (isAtLeastOneChecked) {
      list.forEach((checkbox) =>
        (checkbox as HTMLInputElement).classList.remove("field-required"),
      )
    } else {
      list.forEach((checkbox) =>
        (checkbox as HTMLInputElement).classList.add("field-required"),
      )
    }

    return
  }

  if (typeof value === "string") {
    if (options?.customStringCheck) {
      isValueInvalid = !options?.customStringCheck(value)
    } else {
      isValueInvalid = value === ""
    }
  } else {
    isValueInvalid = value.size === 0
  }

  if (isValueInvalid && valueIsHTMLElement(input)) {
    input.classList.add("field-required")
  }
}
