import { Dispatch, FC, HTMLInputTypeAttribute, SetStateAction } from "react"

interface LabelInputProps {
  id: string
  labelText: string
  value?: string
  setState?: Dispatch<SetStateAction<string>>
  inputType?: HTMLInputTypeAttribute
  disabled?: boolean
}

const LabelInput: FC<LabelInputProps> = ({
  labelText,
  id,
  inputType,
  setState,
  ...props
}) => {
  return (
    <div className="form-row">
      <label htmlFor={id}>{labelText}</label>
      <input
        id={id}
        type={inputType ?? "text"}
        max={
          inputType === "date"
            ? new Date().toISOString().split("T")[0]
            : undefined
        }
        onChange={(e) => setState && setState(e.target.value)}
        {...props}
      />
    </div>
  )
}

export default LabelInput
