import {
  InnovationFilterFormArrayKeys,
  InnovationFilterFormKeys,
  InstituteFilterFormArrayKeys,
  InstituteFilterFormKeys,
  SupportFilterFormArrayKeys,
  SupportFilterFormKeys,
} from "../types/filterSlice"

export function instanceOfSupportFilter(
  value: string,
): value is SupportFilterFormKeys {
  return [
    "forms",
    "directions",
    "members",
    "reasons",
    "min_amount",
    "max_amount",
    "search",
  ].includes(value)
}

export function instanceOfInstituteFilter(
  value: string,
): value is InstituteFilterFormKeys {
  return ["forms", "support_forms", "members", "search"].includes(value)
}

export function instanceOfInnovationFilter(
  value: string,
): value is InnovationFilterFormKeys {
  return [
    "industries",
    "innovation_markets",
    "trl",
    "legal_entity",
    "fundraising",
    "innovation_name",
  ].includes(value)
}

export function instanceOfSupportFilterArray(
  value: string,
): value is SupportFilterFormArrayKeys {
  return ["forms", "directions", "members", "reasons"].includes(value)
}

export function instanceOfInstituteFilterArray(
  value: string,
): value is InstituteFilterFormArrayKeys {
  return ["forms", "support_forms", "members"].includes(value)
}

export function instanceOfInnovationFilterArray(
  value: string,
): value is InnovationFilterFormArrayKeys {
  return ["industries", "innovation_markets"].includes(value)
}

export const isNumberArray = (value: any): value is number[] => {
  if (!Array.isArray(value)) return false

  if (value.length === 0) return true

  return typeof value[0] === "number"
}
