import { FormEvent, useEffect, useRef, useState } from "react"
import { toast } from "react-toastify"

import { useActionCreators, useTypedSelector } from "../../hooks/redux"
import { userApi } from "../../services/userService"

const ChangePasswordModal = () => {
  const actions = useActionCreators()

  const [changePassword, { data: changePasswordResponse }] =
    userApi.useChangePasswordMutation()

  const isVisible = useTypedSelector(
    (state) => state.modalReducer.isChangePasswordModalVisible,
  )
  const userId = useTypedSelector((state) => state.userReducer.user?.id)

  const [errorMessage, setErrorMessage] = useState("")

  const oldPasswordRef = useRef<HTMLInputElement | null>(null)
  const newPasswordRef = useRef<HTMLInputElement | null>(null)

  const onClose = () => actions.setChangePasswordModalVisible(false)

  const onSubmit = (e: FormEvent) => {
    e.preventDefault()

    if (!userId) return

    const oldPassword = oldPasswordRef.current?.value
    const newPassword = newPasswordRef.current?.value

    if (!oldPassword) {
      return setErrorMessage("Введите старый пароль!")
    }

    if (!newPassword) {
      return setErrorMessage("Введите корректный новый пароль")
    }

    changePassword({
      id: userId,
      new_password: newPassword,
      old_password: oldPassword,
    })
  }

  useEffect(() => {
    if (!changePasswordResponse) return

    if (changePasswordResponse.status === "error") {
      const errorArray = changePasswordResponse.error_msg

      if (!errorArray) return

      if (errorArray[0] === "invalid_password") {
        setErrorMessage("Старый пароль не совпадает с введенным!")
      }
    }

    if (changePasswordResponse.status === "successful") {
      toast.success("Пароль успешно изменен")
      actions.setChangePasswordModalVisible(false)
    }
  }, [actions, changePasswordResponse])

  return (
    <div id="modal" style={{ display: isVisible ? "block" : "" }}>
      <div className="overlay-modal" onClick={onClose}></div>
      <div className="aside-b subscribe">
        <form onSubmit={onSubmit}>
          <h4>Изменение пароля</h4>
          <div className="form mb-2">
            <input
              type="password"
              className="form-control"
              placeholder="Старый пароль"
              ref={oldPasswordRef}
              onFocus={() => setErrorMessage("")}
            />
          </div>
          <div className="form mb-2">
            <input
              type="password"
              className="form-control"
              placeholder="Новый пароль"
              ref={newPasswordRef}
              onFocus={() => setErrorMessage("")}
            />
          </div>
          {errorMessage && (
            <p className="text-danger my-2 text-center">{errorMessage}</p>
          )}
          <button type="submit" className="btn btn-primary my-2">
            Подтвердить
          </button>
        </form>
      </div>
    </div>
  )
}

export default ChangePasswordModal
