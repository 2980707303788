import { FC } from "react"

import { Innovation } from "../../types/innovations"

interface InfoLabelsProps {
  innovation: Innovation
}

const InfoLabels: FC<InfoLabelsProps> = ({ innovation }) => {
  return (
    <>
      {innovation.trl && (
        <div className="a-firma k-border mb-4">TRL {innovation.trl}</div>
      )}
      {innovation.stage_name && (
        <div className="a-firma k-border mb-4">{innovation.stage_name}</div>
      )}
      {innovation.direction_name && (
        <div className="a-firma k-border mb-4">{innovation.direction_name}</div>
      )}
      {innovation.innovation_markets.map((market) => (
        <div
          className="a-firma k-border mb-4"
          key={market.id_innovation_markets}
        >
          {market.market_name}
        </div>
      ))}
    </>
  )
}

export default InfoLabels
