import { useCallback, useEffect, useMemo, ChangeEvent } from "react"
import { useLocation, useSearchParams } from "react-router-dom"

import Layout from "../components/Layout"
import { instituteApi } from "../services/instituteService"
import InstituteCard from "../components/Cards/InstituteCard"
import InstituteFilter from "../components/ItemsFilter/InstituteFilter"
import { useActionCreators, useTypedSelector } from "../hooks/redux"
import { useDebounce } from "../hooks/useDebounce"
import Paginate from "../components/Paginate"
import { getObjectFromQuery } from "../utils/getObjectFromQuery"

const InstitutesPage = () => {
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const actions = useActionCreators()

  const [getInstitutes, { data: institutes }] =
    instituteApi.useLazyGetInstitutesQuery()

  const filter = useTypedSelector((state) => state.filterReducer.institution)

  const debouncedFilter = useDebounce(filter, 1000)

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    actions.setInstitutesFilter({ id: "search", value: e.target.value })
  }

  const initialPage = useMemo(() => {
    const page = searchParams.get("page")

    if (page === null) return 1

    return +page ?? 1
  }, [searchParams])

  const pageCount = useMemo(
    () => (institutes ? Math.ceil(institutes.count / filter.page_size) : 0),
    [filter.page_size, institutes],
  )

  const onPageChange = useCallback(
    (e: { selected: number }) =>
      actions.setInstitutesFilter({ id: "page", value: e.selected + 1 }),
    [actions],
  )

  useEffect(() => {
    setSearchParams({
      forms: filter.forms.join(","),
      support_forms: filter.support_forms.join(","),
      members: filter.members.join(","),
      search: filter.search,
      page: `${filter.page}`,
      page_size: `${filter.page_size}`,
    })
  }, [filter, location.search, searchParams, setSearchParams])

  useEffect(() => {
    getInstitutes({
      page: debouncedFilter.page,
      search: {
        maxelem: debouncedFilter.page_size,
        institution_forms: debouncedFilter.forms.join(","),
        support_forms: debouncedFilter.support_forms.join(","),
        support_members: debouncedFilter.members.join(","),
        institute_name: debouncedFilter.search,
      },
    })
  }, [debouncedFilter, getInstitutes])

  useEffect(() => {
    actions.setInstitutesFilterFromUrl(
      getObjectFromQuery(location.search, [
        "forms",
        "support_forms",
        "members",
      ]),
    )
  }, [actions, location.search])

  return (
    <Layout title="Институты поддержки - Центр трансфера технологий">
      <div className="row">
        <h1 className="h1-st mb-4 mt-4">Институты поддержки</h1>
        <InstituteFilter />

        <div className="col-xs-12 col-sm-12 col-md-9 col-xxl-9">
          <section className="mb-4 inst">
            <div className="sort mb-4">
              <form className="search" onSubmit={(e) => e.preventDefault()}>
                <input
                  type="text"
                  placeholder="Поиск институтов поддержки"
                  value={filter.search}
                  onChange={onSearch}
                />
                <button type="button">
                  <i className="fa fa-search" aria-hidden="true" />
                </button>
              </form>
            </div>

            <div className="row">
              {institutes?.list.map((institute) => (
                <InstituteCard
                  institute={institute}
                  key={institute.id_institutes}
                />
              ))}

              <div className="clr" />

              <Paginate
                initPage={initialPage - 1}
                pageCount={pageCount || 1}
                onPageChange={onPageChange}
              />
            </div>
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default InstitutesPage
