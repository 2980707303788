export const MAIN_PAGE_ROUTE = "/"
export const STATIC_PAGE_ROUTE = "/:url"
export const PROJECTS_PAGE_ROUTE = "/innovations"
export const PROJECT_PAGE_ROUTE = "/innovations/:id"
export const INNOVATION_CREATE_ROUTE = "/innovations/add"
export const SUPPORTS_PAGE_ROUTE = "/supports"
export const SUPPORT_PAGE_ROUTE = "/support/:id"
export const INSTITUTES_PAGE_ROUTE = "/institutes"
export const INSTITUTE_PAGE_ROUTE = "/institute/:id"
export const NEWS_PAGE_ROUTE = "/news"
export const NEWS_PAGE_TAG_ROUTE = "/news/tag/:tag"
export const NEWS_DETAILED_PAGE_ROUTE = "/news/:id"
export const CONTACTS_PAGE_ROUTE = "/contacts"
export const PERSONAL_ACCOUNT_ROUTE = "/me"
export const RULES_PAGE_ROUTE = "/rules"
export const CONFIRM_EMAIL_PAGE_ROUTE = "/confirmemail"
export const NOT_FOUND_PAGE_ROUTE = "/404"
