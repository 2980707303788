import { FC, useEffect } from "react"

import { HELP_EMAIL } from "../../consts/contacts"
import { authApi } from "../../services/authService"
import { useActionCreators } from "../../hooks/redux"
import Arrow from "../Icons/Arrow"

import TextForm from "./TextForm"

interface PasswordResetProps {
  email: string
  onBack: () => void
}

const PasswordReset: FC<PasswordResetProps> = ({ email, onBack }) => {
  const { setAuthModalVisible } = useActionCreators()

  const [checkEmail, { data: checkEmailResponse }] =
    authApi.useCheckEmailMutation()
  const [resetPassword, { data: resetPasswordResponse }] =
    authApi.useResetPasswordMutation()

  useEffect(() => {
    checkEmail({ email })
  }, [checkEmail, email])

  useEffect(() => {
    if (resetPasswordResponse?.status === "successful") {
      setAuthModalVisible(false)
      onBack()
    }
  }, [setAuthModalVisible, resetPasswordResponse, onBack])

  if (checkEmailResponse?.data?.activate_email) {
    return (
      <TextForm title="Сбросить пароль">
        <button className="btn-back" onClick={onBack}>
          <Arrow />
        </button>
        <p>Вы уверены, что хотите сбросить пароль для аккаунта {email}?</p>
        <button
          type="submit"
          className="btn btn-primary mt-2 ms-0"
          onClick={() => resetPassword({ email })}
        >
          Сбросить
        </button>
      </TextForm>
    )
  }

  if (
    resetPasswordResponse?.status === "error" &&
    resetPasswordResponse?.error_msg &&
    resetPasswordResponse.error_msg[0] === "limit"
  ) {
    return (
      <TextForm title="Ошибка подтверждения">
        Лимит повторных отправок превышен, пожалуйста, проверьте папку Спам и
        напишите нам на почту <a href={`mailto:${HELP_EMAIL}`}>{HELP_EMAIL}</a>
      </TextForm>
    )
  }

  return (
    <TextForm title="Подтверждение аккаунта">
      Ваша регистрация не завершена, мы повторно отправили ссылку активации на
      почту {email}
    </TextForm>
  )
}

export default PasswordReset
