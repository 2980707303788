import { useCallback, useState } from "react"

import { innovationApi } from "../../services/innovationService"

import CheckboxRadioInput from "./Inputs/CheckboxRadioInput"
import TextAreaInput from "./Inputs/TextAreaInput"
import VacancyInputs from "./Inputs/VacancyInputs"

const Demand = () => {
  const { data: defaultFields } = innovationApi.useGetDefaultFieldsQuery()
  const { data: fetchedDemands } = innovationApi.useGetDemandsQuery()

  const [vacanciesId, setVacanciesId] = useState<number[]>([])

  const addVacancy = useCallback(() => {
    setVacanciesId([...vacanciesId, Math.random()])
  }, [vacanciesId])

  const removeVacancy = useCallback(
    (deleteId: number) => {
      setVacanciesId(vacanciesId.filter((id) => id !== deleteId))
    },
    [vacanciesId],
  )

  return (
    <>
      <h3 className="fw-bold mt-5">Запрос</h3>

      {fetchedDemands?.data && (
        <CheckboxRadioInput
          title="Потребность"
          name="demands"
          idProp="id_demands"
          valueProp="demand_name"
          items={fetchedDemands?.data}
        />
      )}

      {defaultFields?.required_resources?.active && (
        <TextAreaInput
          field={defaultFields.required_resources}
          name="required_resources"
        />
      )}

      <div>
        <button
          className="btn btn-secondary w-100"
          type="button"
          onClick={addVacancy}
        >
          Добавить вакансию
        </button>
      </div>

      {vacanciesId.map((id, index) => (
        <VacancyInputs
          key={id}
          idPrefix={`innovation_vacancy_${index}`}
          labelText={`Вакансия ${index + 1}`}
          onRemove={removeVacancy}
          name={`vacancy_${index}`}
          vacancyId={id}
        />
      ))}
    </>
  )
}

export default Demand
