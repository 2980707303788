import { FormEvent, useEffect, useMemo, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import InputMask from "react-input-mask"

import Layout from "../components/Layout"
import { useActionCreators, useTypedSelector } from "../hooks/redux"
import { authApi } from "../services/authService"
import { INNOVATION_CREATE_ROUTE, MAIN_PAGE_ROUTE } from "../consts/routes"
import SubscriptionTable from "../components/SubscriptionTable"
import { miscApi } from "../services/miscService"
import { isFullName } from "../utils/isFullName"
import { isValidPhoneNumber } from "../utils/isValidPhoneNumber"
import { badFio, badPhone } from "../consts/toastErrors"
import { userApi } from "../services/userService"
import ChangePasswordModal from "../components/Modals/ChangePasswordModal"
import LabelInput from "../components/LabelInput"

const PersonalPage = () => {
  const actions = useActionCreators()
  const navigate = useNavigate()

  const { data: regions } = miscApi.useGetRegionsQuery()

  const [logout] = authApi.useLazyLogoutQuery()
  const [saveUser, { data: saveUserResponse }] = userApi.useSaveUserMutation()

  const user = useTypedSelector((state) => state.userReducer.user)

  const [fio, setFio] = useState("")
  const [region, setRegion] = useState(0)
  const [locality, setLocality] = useState("")
  const [birthDate, setBirthDate] = useState("")
  const [work, setWork] = useState("")
  const [position, setPosition] = useState("")
  const [phone, setPhone] = useState("")

  const infoWasChanged = useMemo(() => {
    return (
      fio !== user?.fio ||
      region !== user.id_regions ||
      locality !== user.locality ||
      birthDate !== user.date_of_birth ||
      work !== user.place_of_work_study ||
      position !== user.position ||
      phone !== user.phone
    )
  }, [fio, region, locality, birthDate, work, position, phone, user])

  const onPasswordChange = () => {
    actions.setChangePasswordModalVisible(true)
  }

  const onLogout = () => {
    logout().then(() => {
      toast.success("Вы вышли из аккаунта")
      navigate(MAIN_PAGE_ROUTE)
      actions.setUser(null)
    })
  }

  const onSubmit = (e: FormEvent) => {
    e.preventDefault()

    if (!user?.id || !user.email) return

    if (!isFullName(fio)) {
      return toast.error(badFio)
    }

    if (phone && !isValidPhoneNumber(phone)) {
      return toast.error(badPhone)
    }

    saveUser({
      id: user.id,
      fio,
      id_regions: region,
      position,
      phone: "+" + phone.replace(/\D/g, ""),
      locality,
      date_of_birth: birthDate,
      place_of_work_study: work,
      email: user.email,
    })
  }

  useEffect(() => {
    if (user === null) return

    setFio(user.fio ?? "")
    setRegion(user.id_regions ?? 0)
    setLocality(user.locality ?? "")
    setBirthDate(user.date_of_birth ?? "")
    setWork(user.place_of_work_study ?? "")
    setPosition(user.position ?? "")
    setPhone(user.phone ?? "")
  }, [user])

  useEffect(() => {
    if (!saveUserResponse?.status) return

    if (saveUserResponse.status === "error") {
      toast.error("Ошибка при сохранении, попробуйте еще раз")

      return
    }

    if (saveUserResponse.status === "successful") {
      toast.success("Успешно сохранено")
    }
  }, [saveUserResponse])

  return (
    <Layout title="Личный кабинет - Центр трансфера технологий">
      <form onSubmit={onSubmit}>
        <div className="row lkab">
          <div className="d-flex flex-column align-items-center justify-content-md-between flex-md-row mb-5 mb-md-0">
            <h1 className="h1-st mb-4 mt-4">Личный кабинет</h1>
            <Link
              className="btn btn-primary"
              style={{ height: "fit-content" }}
              to={INNOVATION_CREATE_ROUTE}
            >
              Подать заявку на проект
            </Link>
          </div>

          <div className="col-xs-12 col-xxl-3 col-sm-12 col-md-4">
            <aside>
              <div className="filtr">
                <h5>
                  <a href="#" onClick={onLogout}>
                    Выйти из аккаунта
                    <i className="fa fa-sign-out" aria-hidden="true"></i>
                  </a>
                </h5>
                <h5 className="mt-3 mb-4">
                  <a href="#" onClick={onPasswordChange}>
                    Сменить пароль
                    <i className="fa fa-unlock-alt" aria-hidden="true"></i>
                  </a>
                </h5>
              </div>
            </aside>
          </div>

          <div className="col-xs-12 col-sm-12 col-md-4 col-xxl-4">
            <section className="mb-4 inst">
              <LabelInput
                id="fio"
                labelText="ФИО"
                value={fio}
                setState={setFio}
              />
              <div className="form-row">
                <label htmlFor="region">Регион</label>
                <select
                  id="region"
                  value={region}
                  onChange={(e) => setRegion(+e.target.value)}
                >
                  {regions?.data?.map((region) => (
                    <option value={region.id_regions} key={region.id_regions}>
                      {region.region_name}
                    </option>
                  ))}
                </select>
              </div>
              <LabelInput
                id="locality"
                labelText="Город"
                value={locality}
                setState={setLocality}
              />
              <LabelInput
                id="birth"
                labelText="Дата рождения"
                inputType="date"
                value={birthDate}
                setState={setBirthDate}
              />
              <LabelInput
                id="work"
                labelText="Место работы / учебы"
                value={work}
                setState={setWork}
              />
              <LabelInput
                id="position"
                labelText="Должность"
                value={position}
                setState={setPosition}
              />
            </section>
          </div>

          <div className="col-xs-12 col-sm-12 col-md-4 col-xxl-4">
            <section className="mb-4 inst">
              <div className="form-row">
                <label htmlFor="phone">Телефон</label>
                <InputMask
                  id="phone-number-input"
                  mask="+7 (999) 999-99-99"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  type="tel"
                />
              </div>

              <LabelInput
                id="email"
                labelText="E-mail"
                inputType="email"
                value={user?.email ?? ""}
                disabled
              />
            </section>
          </div>

          <div className="clr"></div>

          <div className="col-xs-12 col-sm-12 col-md-4 col-xxl-4 offset-md-8 offset-xxl-7">
            <button
              className="btn btn-primary podpis btnlk mb-3"
              type="submit"
              disabled={!infoWasChanged}
            >
              Сохранить изменения
            </button>
          </div>
        </div>
      </form>
      <div className="col-xs-12 col-sm-12 col-md-12 col-xl-9 col-xxl-9 offset-md-0 offset-xl-3 offset-xxl-3 subs">
        <hr />
        <h2 className="h2-st">Подписки</h2>

        <SubscriptionTable />
      </div>
      <ChangePasswordModal />
    </Layout>
  )
}

export default PersonalPage
