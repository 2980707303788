import { FC, useEffect, useRef } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { InView, useInView } from "react-intersection-observer"
import { toast } from "react-toastify"

import Layout from "../components/Layout"
import LogoForms from "../components/Innovation/LogoForms"
import HeaderDescription from "../components/Innovation/HeaderDescription"
import TableCard from "../components/Innovation/TableCard"
import SignificantDates from "../components/Innovation/SignificantDates"
import InfoField from "../components/Innovation/InfoField"
import VacancyCard from "../components/Innovation/VacancyCard"
import MemberCard from "../components/Innovation/MemberCard"
import InnovationSlider from "../components/Innovation/InnovationSlider"
import AdditionalInfo from "../components/Innovation/AdditionalInfo"
import { useActionCreators, useTypedSelector } from "../hooks/redux"
import { innovationApi } from "../services/innovationService"
import { NOT_FOUND_PAGE_ROUTE } from "../consts/routes"

import LoaderPage from "./LoaderPage"

const FormatNumber: FC<{ number: number }> = ({ number }) => {
  const formatter = new Intl.NumberFormat("ru-RU", {
    notation: "compact",
    compactDisplay: "short",
  })

  const splitFormattedValue = formatter
    .format(number)
    .split(String.fromCharCode(160))

  if (splitFormattedValue[1] === "млн") {
    splitFormattedValue[1] = splitFormattedValue[1] + "."
  }

  return (
    <>
      <b>{splitFormattedValue[0]}</b>
      {splitFormattedValue[1] && " " + splitFormattedValue[1]} руб.
    </>
  )
}

const InnovationPage = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { setAuthModalVisible } = useActionCreators()

  const [ref] = useInView()

  const { data: defaultFields } = innovationApi.useGetDefaultFieldsQuery()
  const { data: additionalFields } = innovationApi.useGetAdditionalFieldsQuery()
  const [fetchInnovation, { data: innovation }] =
    innovationApi.useLazyGetInnovationQuery()

  const user = useTypedSelector((state) => state.userReducer.user)

  const modalWasShown = useRef(false)

  const showModal = (inView: boolean, entry: IntersectionObserverEntry) => {
    if (modalWasShown.current) return

    if (!defaultFields) return
    if (!additionalFields?.data) return

    if (window.scrollY === 0) return

    if (!user && inView) {
      const { id } = entry.target

      const defaultField = defaultFields[id]
      if (defaultField?.public) return

      const additionalField = additionalFields.data.find(
        (field) => field.field_name === id,
      )
      if (additionalField?.public) return

      modalWasShown.current = true
      toast.warning("Войдите, чтобы увидеть скрытые поля")
      setAuthModalVisible(true)
    }
  }

  useEffect(() => {
    if (!id || !+id) {
      navigate(NOT_FOUND_PAGE_ROUTE)

      return
    }

    if (id) {
      fetchInnovation(+id).then(({ data }) => {
        if (data?.data === null) {
          navigate(NOT_FOUND_PAGE_ROUTE)
        }
      })
    }
  }, [fetchInnovation, id, navigate, user])

  if (!innovation?.data) {
    return <LoaderPage />
  }

  return (
    <Layout
      title={
        innovation?.data?.innovation_name ??
        "Инновационные разработки - Центр трансфера технологий"
      }
    >
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-xxl-12">
          <section className="mt-4 kartochka ki kartvitr">
            <article className="mb-4">
              <div className="article" ref={ref}>
                {user && [1, 5].includes(user.groups_id_groups) && (
                  <a
                    className="btn btn-primary d-inline-block mb-4 col-8 col-sm-6 col-md-4 col-xxl-3"
                    href={`https://admin.permctt.ru/innovations/${id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Редактировать
                  </a>
                )}

                <LogoForms
                  innovation={innovation.data}
                  onPrivateFieldIntersect={showModal}
                />

                <HeaderDescription
                  innovation={innovation.data}
                  onPrivateFieldIntersect={showModal}
                />

                <div className="ki-content col-12 col-md-12">
                  <h2 className="mt-5 mb-2">Подробная информация о проекте</h2>
                  {innovation.data.detailed_info && (
                    <InView as="p" onChange={showModal} id="detailed_info">
                      {innovation.data.detailed_info}
                    </InView>
                  )}

                  {innovation.data.slider_images.length > 0 && (
                    <InnovationSlider
                      sliderImages={innovation.data.slider_images}
                    />
                  )}

                  <div className="vitr-bl-i mb-5 row">
                    {typeof innovation.data.budget === "number" && (
                      <TableCard
                        title="Бюджет проекта"
                        id="budget"
                        onPrivateFieldIntersect={showModal}
                      >
                        {typeof innovation.data.budget === "number" ? (
                          <FormatNumber number={innovation.data.budget} />
                        ) : (
                          <>{innovation.data.budget} руб.</>
                        )}
                      </TableCard>
                    )}
                    {typeof innovation.data.fundraising === "number" && (
                      <TableCard
                        title="Привлеченное финансирование"
                        id="fundraising"
                        onPrivateFieldIntersect={showModal}
                      >
                        {typeof innovation.data.fundraising === "number" ? (
                          <FormatNumber number={innovation.data.fundraising} />
                        ) : (
                          <>{innovation.data.fundraising} руб.</>
                        )}
                      </TableCard>
                    )}
                    {typeof innovation.data.expected_income === "number" && (
                      <TableCard
                        title="Предполагаемый годовой доход через 10 лет"
                        id="expected_income"
                        onPrivateFieldIntersect={showModal}
                      >
                        {typeof innovation.data.expected_income === "number" ? (
                          <FormatNumber
                            number={innovation.data.expected_income}
                          />
                        ) : (
                          <>{innovation.data.expected_income} руб.</>
                        )}
                      </TableCard>
                    )}
                    {innovation.data.legal_entity !== undefined && (
                      <TableCard
                        title="Юридическое лицо"
                        id="legal_entity"
                        onPrivateFieldIntersect={showModal}
                      >
                        {innovation.data.legal_entity === null
                          ? "░░░░░░"
                          : innovation.data.legal_entity
                          ? "оформлено"
                          : "не оформлено"}
                      </TableCard>
                    )}
                    {innovation.data.intellectual_property !== undefined && (
                      <TableCard
                        title="Интеллектуальная собственность"
                        id="intellectual_property"
                        onPrivateFieldIntersect={showModal}
                      >
                        {innovation.data.intellectual_property === null
                          ? "░░░░░░"
                          : innovation.data.intellectual_property
                          ? "защищена"
                          : "не защищена"}
                      </TableCard>
                    )}
                  </div>

                  {innovation.data.finance_sources && (
                    <InView
                      as="p"
                      onChange={showModal}
                      className="mb-5"
                      id="finance_sources"
                    >
                      <b>Источники финансирования: </b>
                      {innovation.data.finance_sources
                        .map((source) => source.finance_sources_name)
                        .join(", ")}
                    </InView>
                  )}

                  <SignificantDates
                    innovation={innovation.data}
                    onPrivateFieldIntersect={showModal}
                  />

                  {innovation.data.target_product && (
                    <InView as="p" onChange={showModal} id="target_product">
                      {innovation.data.target_product}
                    </InView>
                  )}

                  {innovation.data.objectives.filter(Boolean).length > 0 && (
                    <InfoField title="Цели проекта на год">
                      {innovation.data.objectives
                        .filter(Boolean)
                        .map((objective, index) => (
                          <p key={index}>
                            {index + 1}) {objective}
                          </p>
                        ))}
                      n
                    </InfoField>
                  )}

                  {innovation.data.expected_consumer && (
                    <InfoField title="Предполагаемый заказчик / потребитель продукта">
                      <InView
                        as="p"
                        onChange={showModal}
                        id="expected_consumer"
                      >
                        {innovation.data.expected_consumer}
                      </InView>
                    </InfoField>
                  )}

                  {innovation.data.expected_effect && (
                    <InfoField title="Ожидаемый экономический эффект от реализации проекта">
                      <InView as="p" onChange={showModal} id="expected_effect">
                        {innovation.data.expected_effect}
                      </InView>
                    </InfoField>
                  )}

                  <InfoField title="Запрос">
                    <p>
                      {innovation.data.demands
                        .map((demand) => demand.demand_name)
                        .join(", ")}
                    </p>
                  </InfoField>

                  {innovation.data.required_resources && (
                    <InfoField title="Необходимые ресурсы для проекта">
                      <InView
                        as="p"
                        onChange={showModal}
                        id="required_resources"
                      >
                        {innovation.data.required_resources}
                      </InView>
                    </InfoField>
                  )}

                  <AdditionalInfo
                    additionalData={innovation.data.innovation_data}
                    onPrivateFieldIntersect={showModal}
                  />

                  {innovation.data.vacancies.length > 0 && (
                    <>
                      <h2 className="mt-5 mb-2">Вакансии специалистов</h2>
                      <div className="vitr-vakansii row">
                        {innovation.data.vacancies.map((vacancy) => (
                          <VacancyCard
                            key={vacancy.id_vacancies}
                            vacancy={vacancy}
                          />
                        ))}
                      </div>
                    </>
                  )}

                  {innovation.data.members.length > 0 && (
                    <>
                      <h2 className="mt-5 mb-0">Команда</h2>
                      <section className="team">
                        <div className="row">
                          {innovation.data.members.map((member) => (
                            <MemberCard
                              key={member.id_members}
                              member={member}
                            />
                          ))}
                        </div>
                      </section>
                    </>
                  )}
                </div>
              </div>
            </article>
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default InnovationPage
