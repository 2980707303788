import { innovationApi } from "../../services/innovationService"

import GroupInput from "./Inputs/GroupInput"

const Files = () => {
  const { data: defaultFields } = innovationApi.useGetDefaultFieldsQuery()

  return (
    <>
      <h3 className="fw-bold mt-5">Файлы</h3>

      {defaultFields?.presentation?.active && (
        <GroupInput
          id="innovation_presentation"
          name="presentation"
          labelText={defaultFields.presentation.label}
          tooltip={defaultFields.presentation.tooltip}
          inputOptions={{
            required: defaultFields.presentation.required,
            className: "w-100 form-control",
            type: "file",
            accept: ".ppt,.pptx,.pdf",
          }}
        />
      )}

      {defaultFields?.video_presentation_link?.active && (
        <GroupInput
          id="innovation_video_link"
          name="video_presentation_link"
          labelText={defaultFields.video_presentation_link.label}
          tooltip={defaultFields.video_presentation_link.tooltip}
          inputOptions={{
            required: defaultFields.video_presentation_link.required,
            type: "url",
          }}
        />
      )}
    </>
  )
}

export default Files
