import { FC, PropsWithChildren } from "react"

const Card: FC<PropsWithChildren<{ className?: string }>> = ({
  children,
  className,
}) => {
  return (
    <div className="my-3">
      <div className="card">
        <div className={className ? `card-body ${className}` : "card-body"}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default Card
