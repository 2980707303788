import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { SupportFilterForm } from "../../types/supports"
import { InstitutesFilterForm } from "../../types/institutes"
import {
  SetInnovationFilter,
  SetInstitutesFilter,
  SetSupportFilter,
} from "../../types/filterSlice"
import { InnovationsFilterForm } from "../../types/innovations"
import {
  instanceOfInnovationFilterArray,
  instanceOfInstituteFilterArray,
  instanceOfSupportFilterArray,
} from "../../utils/typeguard"
import { isNumberArray } from "../../utils/typeguard"

interface InitialState {
  supports: SupportFilterForm
  institution: InstitutesFilterForm
  innovations: InnovationsFilterForm
}

const initialState: InitialState = {
  supports: {
    forms: [],
    directions: [],
    members: [],
    totalMinAmount: 0,
    totalMaxAmount: 0,
    min_amount: 0,
    max_amount: 0,
    search: "",
    sort: "date_creation-inverse",
    page: 1,
    page_size: 5,
  },
  institution: {
    forms: [],
    support_forms: [],
    members: [],
    search: "",
    page: 1,
    page_size: 8,
  },
  innovations: {
    industries: [],
    innovation_markets: [],
    trl: [],
    legal_entity: null,
    fundraising: null,
    innovation_name: "",
    page: 1,
    page_size: 8,
  },
}

export const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setSupportFilter(state, action: PayloadAction<SetSupportFilter>) {
      const filter = state.supports
      const { id, value } = action.payload

      if (id === "page" && typeof value === "number") {
        filter[id] = value
        return
      }

      if ((id === "search" || id === "sort") && typeof value === "string") {
        filter[id] = value
        return
      }

      if (
        (id === "totalMaxAmount" || id === "totalMinAmount") &&
        typeof value === "number"
      ) {
        filter[id] = value
        return
      }

      if (instanceOfSupportFilterArray(id) && typeof value === "number") {
        if (filter[id].includes(value)) {
          filter[id] = filter[id].filter((n) => n !== value)
        } else {
          filter[id].push(value)
        }
      }
    },
    setSupportFilterFromUrl(
      state,
      action: PayloadAction<Partial<SupportFilterForm>>,
    ) {
      for (const key in action.payload) {
        if (key && Object.keys(initialState.supports).includes(key)) {
          //@ts-ignore
          state.supports[key] = action.payload[key]
        }
      }
    },
    setSupportFilterRange(state, action: PayloadAction<number[]>) {
      const [min, max] = action.payload

      state.supports.min_amount = min
      state.supports.max_amount = max
    },
    setInstitutesFilter(state, action: PayloadAction<SetInstitutesFilter>) {
      const filter = state.institution
      const { id, value } = action.payload

      if (id === "search" && typeof value === "string") {
        filter.search = value
        return
      }

      if (id === "page" && typeof value === "number") {
        filter[id] = value
      }

      if (instanceOfInstituteFilterArray(id) && typeof value === "number") {
        if (filter[id].includes(value)) {
          filter[id] = filter[id].filter((n) => n !== value)
        } else {
          filter[id].push(value)
        }
      }
    },
    setInstitutesFilterFromUrl(
      state,
      action: PayloadAction<Partial<InstitutesFilterForm>>,
    ) {
      for (const key in action.payload) {
        if (key && Object.keys(initialState.institution).includes(key)) {
          //@ts-ignore
          state.institution[key] = action.payload[key]
        }
      }
    },
    setInnovationFilter(state, action: PayloadAction<SetInnovationFilter>) {
      const filter = state.innovations
      const { id, value } = action.payload

      if (
        (id === "fundraising" || id === "legal_entity") &&
        (typeof value === "boolean" || value === null)
      ) {
        filter[id] = value
        return
      }

      if (id === "innovation_name" && typeof value === "string") {
        filter[id] = value
        return
      }

      if (id === "trl" && isNumberArray(value)) {
        filter[id] = value
        return
      }

      if (instanceOfInnovationFilterArray(id) && typeof value === "number") {
        if (filter[id].includes(value)) {
          filter[id] = filter[id].filter((n) => n !== value)
        } else {
          filter[id].push(value)
        }
      }
    },
    setInnovationFilterFromUrl(
      state,
      action: PayloadAction<Partial<SupportFilterForm>>,
    ) {
      for (const key in action.payload) {
        if (key && Object.keys(initialState.innovations).includes(key)) {
          //@ts-ignore
          state.innovations[key] = action.payload[key]
        }
      }
    },
    resetState(state) {
      state.institution = initialState.institution
      state.supports = initialState.supports
      state.innovations = initialState.innovations
    },
  },
})

export const filterReducer = filterSlice.reducer
