import { memo } from "react"

import {
  CONTACTS_PAGE_ROUTE,
  INSTITUTES_PAGE_ROUTE,
  MAIN_PAGE_ROUTE,
  NEWS_PAGE_ROUTE,
  PROJECTS_PAGE_ROUTE,
  SUPPORTS_PAGE_ROUTE,
} from "../../consts/routes"

import NavItem from "./NavItem"

const NavItems = () => {
  return (
    <div className="row">
      <ul>
        <NavItem title="О центре" link={MAIN_PAGE_ROUTE} />
        <NavItem title="Витрина проектов" link={PROJECTS_PAGE_ROUTE} />
        <NavItem title="Меры поддержки" link={SUPPORTS_PAGE_ROUTE} />
        <NavItem title="Институты поддержки" link={INSTITUTES_PAGE_ROUTE} />
        <NavItem title="Новости" link={NEWS_PAGE_ROUTE} />
        <NavItem title="Команда" link="/komanda" />
        <NavItem title="Контакты" link={CONTACTS_PAGE_ROUTE} />
      </ul>
    </div>
  )
}

export default memo(NavItems)
