import { Link } from "react-router-dom"

import Layout from "../components/Layout"
import { MAIN_PAGE_ROUTE } from "../consts/routes"

const NotFoundPage = () => {
  return (
    <Layout title="Не найдено - Центр трансфера технологий">
      <div className="row">
        <h1 className="h1-st mb-4 mt-4">Страница не найдена!</h1>

        <div className="col-5 col-sm-8 col-xl-4 col-xxl-5 col-lg-8 col-md-8 homebtn">
          <Link to={MAIN_PAGE_ROUTE}>
            <i className="fa fa-home" aria-hidden="true" />
            Главная
          </Link>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </Layout>
  )
}

export default NotFoundPage
