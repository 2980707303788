import { FC } from "react"
import { Link } from "react-router-dom"

import { PROJECTS_PAGE_ROUTE } from "../../consts/routes"
import { HOST } from "../../consts/hosts"
import defaultImage from "../../resources/default_image.png"
import { InnovationShort } from "../../types/innovations"

interface innovationCardProps {
  innovation: InnovationShort
}

const InnovationCard: FC<innovationCardProps> = ({ innovation }) => {
  return (
    <article className="col-6 mb-5">
      <div className="article">
        <Link
          to={`${PROJECTS_PAGE_ROUTE}/${innovation.id_innovations}`}
          className="stretched-link"
        >
          <div className="inst-img d-flex">
            <img
              className="d-block m-auto mh-100 mw-100"
              src={
                innovation.wallpaper
                  ? `${HOST}${innovation.wallpaper}`
                  : defaultImage
              }
              alt={innovation.innovation_name}
            />
          </div>
          <h2 className="mt-3 mb-3">{innovation.innovation_name}</h2>

          {innovation.industries &&
            innovation.industries.map((industry) => (
              <div className="float-start a-univ" key={industry.id_industries}>
                {industry.industry_name}
              </div>
            ))}

          <div className="clr"></div>
          <div className="float-start a-gorod">TRL {innovation.id_trls}</div>
          <div className="clr"></div>
        </Link>
      </div>
      <div className="float-end homebtn">
        <Link to={`${PROJECTS_PAGE_ROUTE}/${innovation.id_innovations}`}>
          Подробнее
        </Link>
      </div>
    </article>
  )
}

export default InnovationCard
