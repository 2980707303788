import { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import parse from "html-react-parser"

import Layout from "../components/Layout"
import { staticApi } from "../services/staticService"

const StaticPage = () => {
  const { url } = useParams()
  const navigate = useNavigate()

  const [fetchPage, { data: statPage }] = staticApi.useLazyGetOneQuery()

  useEffect(() => {
    if (url) {
      fetchPage(url)
    }
  }, [fetchPage, url])

  useEffect(() => {
    if (statPage?.data === null) {
      navigate("/404")
    }
  }, [navigate, statPage?.data])

  return (
    <Layout
      title={statPage?.data?.title_meta || "Центр трансфера технологий"}
      description={statPage?.data?.description_meta}
      keywords={statPage?.data?.keywords_meta}
    >
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-xxl-12">
          <section className="mt-4 kartochka knews">
            <article className="mb-4">
              <div className="clr" />
              {parse(statPage?.data?.content ?? "")}
            </article>
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default StaticPage
