import { createApi } from "@reduxjs/toolkit/query/react"

import { IDefaultResponse } from "../types/api"
import { User, UserAuth, UserRegister } from "../types/user"

import { baseQuery } from "./baseQuery"

export const authApi = createApi({
  reducerPath: "auth",
  baseQuery,
  endpoints: (build) => ({
    checkEmail: build.mutation<
      IDefaultResponse<{ activate_email: boolean }>,
      { email: string }
    >({
      query: (body) => ({
        url: "/checkemail",
        body,
        method: "POST",
      }),
    }),
    resetPassword: build.mutation<IDefaultResponse<string>, { email: string }>({
      query: (body) => ({
        url: "/forgotpassword",
        body,
        method: "POST",
      }),
    }),
    sendAuth: build.mutation<IDefaultResponse<User>, UserAuth>({
      query: (body) => ({
        url: "/sendauth",
        body,
        method: "POST",
      }),
    }),
    sendRegister: build.mutation<IDefaultResponse<void>, UserRegister>({
      query: (body) => ({
        url: "/sendregister",
        body,
        method: "POST",
      }),
    }),
    logout: build.query<void, void>({
      query: () => "/logout",
    }),
    confirmEmail: build.mutation<
      IDefaultResponse<User>,
      { id: number; hash: string }
    >({
      query: (body) => ({
        url: "/confirmregister",
        body,
        method: "POST",
      }),
    }),
  }),
})
