import { Dispatch, FC, memo, SetStateAction, useEffect, useRef } from "react"

import Spinner from "../Spinner"

interface EmailFormProps {
  email: string
  setEmail: Dispatch<SetStateAction<string>>
  onBtnClick: () => void
  isBtnDisabled: boolean
  isLoading: boolean
}

const EmailForm: FC<EmailFormProps> = ({
  email,
  setEmail,
  isBtnDisabled,
  isLoading,
  onBtnClick,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    setTimeout(() => {
      inputRef.current && inputRef.current?.focus()
    }, 1000)
  }, [])

  return (
    <form>
      <h4>Вход и регистрация</h4>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <div className="form mb-2">
            <input
              type="email"
              className="form-control"
              placeholder="Email"
              value={email}
              ref={inputRef}
              onKeyDown={(e) => e.key === "Enter" && onBtnClick()}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="clr"></div>
          <button
            type="button"
            onClick={onBtnClick}
            disabled={isBtnDisabled}
            className="btn btn-primary my-2"
          >
            Продолжить
          </button>
        </>
      )}
    </form>
  )
}

export default memo(EmailForm)
