import { useEffect } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"

import arrowIcon2 from "../resources/arrow2.png"
import Layout from "../components/Layout"
import { supportApi } from "../services/supportService"
import { INSTITUTES_PAGE_ROUTE, NOT_FOUND_PAGE_ROUTE } from "../consts/routes"
import { useTypedSelector } from "../hooks/redux"

const INSTITUTE_PAGE = INSTITUTES_PAGE_ROUTE.slice(0, -1)

const SupportPage = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  const [fetchSupport, { data: support }] = supportApi.useLazyGetSupportQuery()

  const user = useTypedSelector((state) => state.userReducer.user)

  useEffect(() => {
    if (id === undefined || !+id) {
      navigate(NOT_FOUND_PAGE_ROUTE)
    }

    if (id) {
      fetchSupport(+id)
    }
  }, [fetchSupport, id, navigate])

  return (
    <Layout
      title={
        support?.data?.title_meta ||
        "Меры поддержки - Центр трансфера технологий"
      }
      description={support?.data?.description_meta}
      keywords={support?.data?.keywords_meta}
    >
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-xxl-12">
          <section className="mt-4 kartochka">
            <article className="mb-4">
              <div className="article">
                {user && [1, 5].includes(user.groups_id_groups) && (
                  <a
                    className="btn btn-primary d-inline-block mb-4 col-8 col-sm-6 col-md-4 col-xxl-3"
                    href={`https://admin.permctt.ru/supports/${id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Редактировать
                  </a>
                )}

                <div className="float-end">
                  {support?.data?.support_forms &&
                    support.data.support_forms.map((form) => (
                      <div
                        className="a-forma meraforma float-end"
                        key={form.id_support_forms}
                      >
                        {form.support_form_name}
                      </div>
                    ))}
                </div>

                <h1 className="mb-4 mt-1">
                  {support?.data?.support_name_manual ||
                    support?.data?.support_name}
                </h1>

                {support?.data?.institute_name && support?.data.id_institutes && (
                  <div className="a-firma mb-2">
                    <Link
                      to={`${INSTITUTE_PAGE}/${support.data.id_institutes}`}
                      className="stretched-link"
                    >
                      {support.data.institute_name}
                    </Link>
                  </div>
                )}

                <div className="clr" />
                {support?.data?.no_finance ? (
                  <div className="k-cena mt-4 mb-4">
                    Нефинансовая мера поддержки
                  </div>
                ) : (
                  <div className="a-cena mt-4 mb-4">
                    <b>до {support?.data?.amount}</b>
                    <u>тыс. руб.</u>
                  </div>
                )}
                {!!support?.data?.remained_count && (
                  <div className="a-srok mt-4 mb-4">
                    <i className="fa fa-clock-o" aria-hidden="true" />
                    <u>
                      осталось {support?.data.remained_count}{" "}
                      {support?.data.remained_days}
                    </u>
                  </div>
                )}

                <div
                  dangerouslySetInnerHTML={{
                    __html: support?.data?.description || "",
                  }}
                />

                {/*<p><b className="k-srok">Срок реализации:</b> 1 год</p>*/}

                <div className="homebtn mt-5">
                  <a
                    href={support?.data?.site}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Подробнее на странице
                    <img className="arrow2" src={arrowIcon2} alt="стрелка" />
                  </a>
                </div>

                <div className="a-date k-dp">
                  <i className="fa fa-clock-o" aria-hidden="true" />
                  Дата публикации
                  <br />
                  {support?.data?.date_creation_dd}
                </div>
                {support?.data?.date_end_dd && (
                  <div className="a-date k-do">
                    <i className="fa fa-fire" aria-hidden="true" />
                    Дата окончания приёма
                    <br />
                    {support?.data.date_end_dd}
                  </div>
                )}
              </div>
            </article>
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default SupportPage
