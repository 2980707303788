import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const initialState = {
  isAuthModalVisible: false,
  isChangePasswordModalVisible: false,
  isSubscribeModalVisible: false,
  isUnsubscribeModalVisible: false,
  isChangeSubscriptionTitleModalVisible: false,
}

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    setAuthModalVisible(state, action: PayloadAction<boolean>) {
      state.isAuthModalVisible = action.payload
    },
    setChangePasswordModalVisible(state, action: PayloadAction<boolean>) {
      state.isChangePasswordModalVisible = action.payload
    },
    setSubscribeModalVisible(state, action: PayloadAction<boolean>) {
      state.isSubscribeModalVisible = action.payload
    },
    setUnsubscribeModalVisible(state, action: PayloadAction<boolean>) {
      state.isUnsubscribeModalVisible = action.payload
    },
    setChangeSubscriptionTitleModalVisible(
      state,
      action: PayloadAction<boolean>,
    ) {
      state.isChangeSubscriptionTitleModalVisible = action.payload
    },
  },
})

export const modalReducer = modalSlice.reducer
