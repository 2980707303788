import { FC, memo } from "react"
import { Link } from "react-router-dom"

import arrowIcon from "../../resources/arrow.png"
import { Support } from "../../types/supports"
import { INSTITUTES_PAGE_ROUTE, SUPPORTS_PAGE_ROUTE } from "../../consts/routes"

interface SupportCardProps {
  support: Support
}

const SUPPORT_PAGE = SUPPORTS_PAGE_ROUTE.slice(0, -1)
const INSTITUTE_PAGE = INSTITUTES_PAGE_ROUTE.slice(0, -1)

const SupportCard: FC<SupportCardProps> = ({ support }) => {
  return (
    <article className="mb-4" key={support.id_supports}>
      <div className="article">
        <Link
          to={`${SUPPORT_PAGE}/${support.id_supports}`}
          className="stretched-link"
        >
          {!!support.date_end && (
            <div className="a-date float-start">
              <i className="fa fa-clock-o" aria-hidden="true" />
              {support.date_end}
            </div>
          )}

          <div className="float-end">
            {support.support_forms &&
              support.support_forms.map((form) => (
                <div className="a-forma float-end" key={form.support_form_name}>
                  {form.support_form_name}
                </div>
              ))}
          </div>
          <div className="clr" />
          <h2 className="mt-4 mb-5">
            {support.support_name_manual || support.support_name}
          </h2>
          <div className="float-end">
            {support.no_finance ? (
              <div className="k-cena mt-5 mb-4">
                Нефинансовая мера поддержки
              </div>
            ) : (
              <div className="a-cena mb-3">
                <b>{support.amount.toLocaleString("ru-RU")}</b>
                <u>тыс. руб.</u>
              </div>
            )}
            <div className="a-srok float-end">
              {!!support.remained_count && (
                <i className="fa fa-clock-o" aria-hidden="true" />
              )}
              <u>
                {support.remained_count} {support.remained_days}{" "}
                <img className="arrow" src={arrowIcon} alt="стрелка" />
              </u>
            </div>
          </div>
          <div className="clr" />
        </Link>
      </div>
      {support.institute_name && support.id_institutes && (
        <div className="float-start">
          <div className="a-firma">
            <Link
              to={`${INSTITUTE_PAGE}/${support.id_institutes}`}
              className="stretched-link"
            >
              {support.institute_name}
            </Link>
          </div>
        </div>
      )}
    </article>
  )
}

export default memo(SupportCard)
