import { useEffect } from "react"
import { Route, Routes, Navigate } from "react-router-dom"

import { authRoutes, routes } from "../routes/routes"
import { useActionCreators, useTypedSelector } from "../hooks/redux"
import { userApi } from "../services/userService"
import { MAIN_PAGE_ROUTE } from "../consts/routes"
import LoaderPage from "../pages/LoaderPage"

import AuthModal from "./Modals/AuthModal"

const AppRouter = () => {
  const actions = useActionCreators()

  const { data: userIdResponse } = userApi.useGetIdQuery()
  const [getCurrentUser, { data: currentUser }] =
    userApi.useLazyGetCurrentUserQuery()

  const userId = useTypedSelector((state) => state.userReducer.user?.id)

  useEffect(() => {
    if (!userIdResponse?.userid) return

    getCurrentUser(userIdResponse.userid)
  }, [getCurrentUser, userIdResponse])

  useEffect(() => {
    if (!(currentUser?.status === "successful")) return

    currentUser.data && actions.setUser(currentUser.data)
  }, [actions, currentUser])

  return (
    <>
      {!userIdResponse ||
      (userIdResponse.status === "successful" && !currentUser) ? (
        <LoaderPage />
      ) : (
        <>
          <Routes>
            {routes.map(({ path, Component }) => (
              <Route path={path} element={<Component />} key={path} />
            ))}
            {authRoutes.map(({ path, Component }) => (
              <Route
                path={path}
                element={
                  currentUser?.data?.id || userId ? (
                    <Component />
                  ) : (
                    <Navigate to={MAIN_PAGE_ROUTE} />
                  )
                }
                key={path}
              />
            ))}
          </Routes>
          {!userId && <AuthModal />}
        </>
      )}
    </>
  )
}

export default AppRouter
