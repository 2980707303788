import { FC, memo, useMemo } from "react"
import { Link } from "react-router-dom"

import { NETWORKS } from "../../consts/contacts"
import {
  CONTACTS_PAGE_ROUTE,
  MAIN_PAGE_ROUTE,
  PERSONAL_ACCOUNT_ROUTE,
} from "../../consts/routes"
import { useActionCreators, useTypedSelector } from "../../hooks/redux"

import HeaderForm from "./HeaderForm"

interface HeaderProps {
  onBurgerClick: () => void
}

const Header: FC<HeaderProps> = ({ onBurgerClick }) => {
  const { setAuthModalVisible } = useActionCreators()
  const user = useTypedSelector((state) => state.userReducer.user)

  const fio = useMemo(() => {
    const fioArr = user?.fio.split(" ")

    if (!fioArr) return

    if (!fioArr[1]) return fioArr[0]

    return `${fioArr[0]} ${fioArr[1].at(0)}.`
  }, [user?.fio])

  return (
    <header>
      <div className="container">
        <div className="d-none d-md-block">
          <div className="row">
            <div className="col-2 col-sm-4 col-md-4 col-lg-4 col-xl-2 logo">
              <Link to={MAIN_PAGE_ROUTE}>
                <img src="./logo.svg" alt="ЦТТ" />
              </Link>
            </div>

            <div className="col-5 col-sm-8 col-xl-5 col-xxl-5 col-lg-8 col-md-8 homebtn">
              <Link to={MAIN_PAGE_ROUTE}>
                <i className="fa fa-home" aria-hidden="true" />
                Главная
              </Link>
              <HeaderForm />
            </div>

            <div className="col-3 col-sm-7 col-xl-5 col-xxl-5 col-md-12 col-lg-12 lk float-end">
              {user !== null ? (
                <Link className="namelk namelk-ml" to={PERSONAL_ACCOUNT_ROUTE}>
                  Личный кабинет ({fio})
                </Link>
              ) : (
                <a
                  className="namelk namelk-ml"
                  style={{ cursor: "pointer" }}
                  onClick={() => setAuthModalVisible(true)}
                >
                  Личный кабинет
                </a>
              )}
              <Link className="namelk" to={CONTACTS_PAGE_ROUTE}>
                Написать в центр
              </Link>
              <div className="soc float-end">{NETWORKS}</div>
            </div>
          </div>
        </div>
        <div className="d-block d-md-none">
          <div className="row">
            <div className="col-12">
              <div className="logo float-start">
                <Link to={MAIN_PAGE_ROUTE}>
                  <img
                    src="https://permctt.ru/static/media/logo.7c4caa53.svg"
                    alt="ЦТТ"
                  />
                </Link>
              </div>

              <div className="soc float-end">{NETWORKS}</div>
            </div>

            <div className="col-12">
              <HeaderForm />
            </div>

            <div className="col-9 lk">
              {user !== null ? (
                <Link className="namelkmob" to={PERSONAL_ACCOUNT_ROUTE}>
                  Личный кабинет ({fio})
                </Link>
              ) : (
                <a
                  className="namelkmob"
                  style={{ cursor: "pointer" }}
                  onClick={() => setAuthModalVisible(true)}
                >
                  Личный кабинет
                </a>
              )}
              <Link className="namelkmob" to={CONTACTS_PAGE_ROUTE}>
                Написать в центр
              </Link>
            </div>

            <div className="col-3 menubar">
              <a id="menubarbtn" onClick={onBurgerClick}>
                <i className="fa fa-bars" aria-hidden="true" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default memo(Header)
