import { FC, useEffect, useMemo, useRef, useState } from "react"

import { HOST } from "../../consts/hosts"
import { InnovationSliderImage } from "../../types/innovations"

interface InnovationSliderProps {
  sliderImages: InnovationSliderImage[]
}

const InnovationSlider: FC<InnovationSliderProps> = ({ sliderImages }) => {
  const [slide, setSlide] = useState(0)

  const sliderRef = useRef<HTMLDivElement | null>(null)

  const sliderWidth = sliderRef.current?.offsetWidth ?? 5000

  const offset = useMemo(() => {
    return slide * (document.body.offsetWidth > 768 ? 400 : 256)
  }, [slide])

  const nextSlide = () => {
    setSlide((prev) => prev + 1)
  }

  const prevSlide = () => {
    setSlide((prev) => prev - 1)
  }

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.style.transform = `translateX(-${offset}px)`
    }
  }, [offset])

  return (
    <div className="gal-vitr mt-5 mb-5">
      <a
        className="gal-btn gal-left d-xxl-none"
        hidden={slide === 0}
        onClick={prevSlide}
      >
        <i className="fa fa-angle-left" aria-hidden="true"></i>
      </a>
      <div className="vitr-slider d-flex" ref={sliderRef}>
        {sliderImages.map((image) => (
          <div
            key={image.id_innovation_images}
            className="vitr-card flex-shrink-0"
          >
            <img src={HOST + image.url_file} alt="" />
          </div>
        ))}
      </div>
      <div
        className="vitr-shadow position-absolute top-0 h-100 w-25 d-xxl-none"
        hidden={sliderWidth < 350}
      ></div>
      <a
        className="gal-btn gal-right d-xxl-none"
        hidden={slide === sliderImages.length - 1}
        onClick={nextSlide}
      >
        <i className="fa fa-angle-right" aria-hidden="true"></i>
      </a>
    </div>
  )
}

export default InnovationSlider
