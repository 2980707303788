import { useCallback, useState } from "react"

import { innovationApi } from "../../services/innovationService"

import GroupInput from "./Inputs/GroupInput"
import TeamMemberInputs from "./Inputs/TeamMemberInputs"

const Team = () => {
  const { data: defaultFields } = innovationApi.useGetDefaultFieldsQuery()

  const [membersId, setMembersId] = useState<number[]>([])

  const addMember = useCallback(() => {
    setMembersId([...membersId, Math.random()])
  }, [membersId])

  const removeMember = useCallback(
    (deleteId: number) => {
      setMembersId(membersId.filter((id) => id !== deleteId))
    },
    [membersId],
  )

  return (
    <>
      <h3 className="fw-bold mt-5">Команда</h3>

      <TeamMemberInputs
        idPrefix="head"
        labelText="Руководитель проекта"
        name="member_0"
        isLeader
      />
      {defaultFields?.count_members?.active && (
        <GroupInput
          id="innovation_team_amount"
          name="count_members"
          labelText={defaultFields.count_members.label}
          tooltip={defaultFields.count_members.tooltip}
          inputOptions={{
            required: defaultFields.count_members.required,
            className: "form-control w-auto",
            type: "number",
            defaultValue: "1",
            min: 1,
          }}
        />
      )}

      <div>
        <button
          className="btn btn-secondary w-100"
          type="button"
          onClick={addMember}
        >
          Добавить участника
        </button>
      </div>

      {membersId.map((id, index) => (
        <TeamMemberInputs
          key={id}
          idPrefix={"member_" + (index + 1)}
          name={`member_${index + 1}`}
          labelText={"Участник " + (index + 1)}
          onRemove={removeMember}
          memberId={id}
        />
      ))}
    </>
  )
}

export default Team
