import { FC, memo, FocusEvent, ChangeEvent, InputHTMLAttributes } from "react"
import { toast } from "react-toastify"

import Tooltip from "../../Tooltip"
import { isLink } from "../../../utils/isLink"
import { isPositiveInteger } from "../../../utils/isPositiveInteger"

interface GroupInputProps {
  id: string
  labelText: string
  name: string
  tooltip?: string
  labelClass?: string
  inputOptions?: InputHTMLAttributes<HTMLInputElement>
}

const GroupInput: FC<GroupInputProps> = ({
  labelText,
  id,
  name,
  labelClass,
  tooltip,
  inputOptions,
}) => {
  const onBlur = (e: FocusEvent<HTMLInputElement>) => {
    const inputType = inputOptions?.type

    if (inputType === "url" && !isLink(e.target.value)) {
      e.target.classList.add("field-required")

      return
    }

    if (inputType === "number" && !isPositiveInteger(e.target.valueAsNumber)) {
      e.target.classList.add("field-required")

      return
    }

    if (!inputOptions?.required) return

    if (inputType === undefined || inputType === "text") {
      e.target.value === "" && e.target.classList.add("field-required")

      return
    }
  }

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === "slider_images") {
      if (e.target.files && e.target.files.length > 3) {
        toast.warning("Вы можете загрузить не более 3 картинок")
        e.target.value = ""
      }
    }

    e.target.classList.remove("field-required")
  }

  return (
    <div className="form-row my-3">
      <p className="d-flex align-items-center justify-content-between">
        <label htmlFor={id} className={"m-0" + (labelClass ?? "")}>
          {inputOptions?.required && <span className="required">*</span>}
          {labelText}
        </label>
        {tooltip && <Tooltip tooltip={tooltip} />}
      </p>
      <input
        className={inputOptions?.className ? inputOptions?.className : "w-100"}
        type={inputOptions?.type ?? "text"}
        id={id}
        name={name}
        {...inputOptions}
        onChange={onChange}
        onBlur={onBlur}
      />
    </div>
  )
}

export default memo(GroupInput)
