import { FC } from "react"

import InputRow from "../../InputRow/InputRow"
import Tooltip from "../../Tooltip"

interface CheckboxRadioInputProps {
  title: string
  required?: boolean
  items: any[]
  idProp: string
  valueProp: string
  name: string
  tooltip?: string
  type?: "radio" | "checkbox"
}

const CheckboxRadioInput: FC<CheckboxRadioInputProps> = ({
  title,
  required,
  idProp,
  valueProp,
  items,
  name,
  tooltip,
  type,
}) => {
  return (
    <div className="my-3">
      <p className="d-flex align-items-center justify-content-between">
        <span>
          {required && <span className="required">*</span>}
          {title}
        </span>
        {tooltip && <Tooltip tooltip={tooltip} />}
      </p>
      {items.map((item) => (
        <div key={item[idProp]}>
          <InputRow
            label={item[valueProp]}
            id={name + "_" + item[idProp]}
            dataId={item[idProp]}
            name={name}
            type={type ?? "checkbox"}
          />
        </div>
      ))}
    </div>
  )
}

export default CheckboxRadioInput
