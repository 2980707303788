import { FC, memo } from "react"

import Card from "../../Card"

import GroupInput from "./GroupInput"

interface VacancyInputsProps {
  idPrefix: string
  labelText: string
  name: string
  vacancyId: number
  onRemove: (id: number) => void
}

const VacancyInputs: FC<VacancyInputsProps> = ({
  idPrefix,
  labelText,
  onRemove,
  name,
  vacancyId,
}) => {
  return (
    <Card className="vacancy">
      <div className="d-flex align-items-center justify-content-between mb-4">
        <h5 className="card-title fw-bold">{labelText}</h5>
        <button
          type="button"
          className="btn btn-danger"
          onClick={() => onRemove(vacancyId)}
        >
          Удалить
        </button>
      </div>
      <GroupInput
        id={`${idPrefix}_name`}
        name={`${name}_name`}
        labelText="Название вакансии"
        inputOptions={{
          className: "w-100",
          required: true,
        }}
      />
      <GroupInput
        id={`${idPrefix}_description`}
        name={`${name}_description`}
        labelText="Описание вакансии"
        inputOptions={{
          className: "w-100",
          required: true,
        }}
      />
      <GroupInput
        id={`${idPrefix}_amount`}
        name={`${name}_amount`}
        labelText="Количество мест"
        inputOptions={{
          className: "w-auto",
          required: true,
          type: "number",
          defaultValue: "1",
          min: 1,
        }}
      />
    </Card>
  )
}

export default memo(VacancyInputs)
