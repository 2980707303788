import { FC } from "react"

import { InnovationMember } from "../../types/innovations"
import defaultImage from "../../resources/default_image.png"
import { HOST } from "../../consts/hosts"

const MemberCard: FC<{ member: InnovationMember }> = ({ member }) => {
  return (
    <div className="col-6 col-md-6 col-lg-4 mt-4">
      <div className="article">
        <div className="photo">
          <img src={member.logo ? HOST + member.logo : defaultImage} alt="" />
        </div>
        <div className="person">
          <h5>{member.fio}</h5>
          <p>{member.position}</p>
          <p>{member.degree}</p>
        </div>
        {member.leader && <div className="zvanie">Руководитель проекта</div>}
      </div>
    </div>
  )
}

export default MemberCard
