import { FC, useState } from "react"

import {
  InnovationFilterFormKeys,
  InstituteFilterFormKeys,
  SupportFilterFormKeys,
} from "../../types/filterSlice"

import FilterCheck from "./FilterCheck"

interface FilterGroupProps {
  title: string
  initIsOpen: boolean
  items: any[] | undefined
  id: string
  name:
    | SupportFilterFormKeys
    | InstituteFilterFormKeys
    | InnovationFilterFormKeys
  reducerName: "supports" | "institution" | "innovations"
}

const FilterGroup: FC<FilterGroupProps> = ({
  id,
  title,
  initIsOpen,
  items,
  name,
  reducerName,
}) => {
  const [isOpened, setIsOpened] = useState(initIsOpen)

  return (
    <>
      <h5 className="mt-4">
        <a
          data-bs-toggle="collapse"
          href={`#${id}`}
          role="button"
          aria-expanded="true"
          aria-controls={id}
          onClick={() => setIsOpened((prev) => !prev)}
        >
          {title}
          <i
            aria-hidden="true"
            className={isOpened ? "fa fa-caret-up" : "fa fa-caret-down"}
          />
        </a>
      </h5>
      <div id={id} className={`collapse${initIsOpen ? " show" : ""}`}>
        {items?.map((item) => (
          <FilterCheck
            key={item.id}
            id={item.id}
            title={item.title}
            name={name}
            type={reducerName}
          />
        ))}
      </div>
    </>
  )
}

export default FilterGroup
