import { innovationApi } from "../../services/innovationService"

import TextAreaInput from "./Inputs/TextAreaInput"
import CheckboxRadioInput from "./Inputs/CheckboxRadioInput"

const AdditionalInfo = () => {
  const { data: additionalFields } = innovationApi.useGetAdditionalFieldsQuery()

  return (
    <>
      <h3 className="fw-bold mt-5">Дополнительная информация</h3>

      {additionalFields?.data?.map((field) => {
        const fieldType = field.id_field_types

        if (fieldType === 1) {
          return (
            <TextAreaInput
              key={field.id_innovation_fields}
              field={field}
              name={field.field_name}
            />
          )
        }

        if (fieldType === 2 || fieldType === 3) {
          return (
            <CheckboxRadioInput
              key={field.id_innovation_fields}
              title={field.label}
              name={field.field_name}
              items={
                field.sets?.map((set) => ({
                  id: set.id_field_sets,
                  value: set.set_value,
                })) ?? []
              }
              idProp="id"
              valueProp="value"
              required={field.required}
              tooltip={field.tooltip}
              type={fieldType === 2 ? "radio" : "checkbox"}
            />
          )
        }

        return <div key={field.id_innovation_fields}></div>
      })}
    </>
  )
}

export default AdditionalInfo
